import React from "react";
import { useTranslation } from "react-i18next";
import token1 from "../assets/images/piechart.png";
const Tokenomic = () => {
  const { t } = useTranslation();
  return (
    <div className="tokenomic_section">
      <div className="tokenomic_box relative">
        <div className="container" data-aos="fade-right">
          <div className="row">
            <div className="col-md-12">
              <div className="heading_box text-center mb-5">
                <h6 className="text-light"> {t("teko.heading")}</h6>
                <h1 className="text-light">{t("teko.subheading")}</h1>
                <p
                  className="text-light"
                  dangerouslySetInnerHTML={{ __html: t("teko.para") }}
                ></p>
              </div>
            </div>
          </div>
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-8">
              <div className="into_image text-center mb-4">
                <img
                  src={token1}
                  alt="Gamw Intro"
                  className="Intro w-100"
                  // width="520px"
                />
              </div>
            </div>
            {/* <div className="col-lg-6">
              <div className="into_image text-center mb-4">
                <img
                  src={token2}
                  alt="Gamw Intro"
                  className="Intro w-100"
                  // width="674px"
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tokenomic;
