import React from "react";
import Logo from "../assets/images/logo.png";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="footer_bg relative">
      <div className="container">
        <div className="main_footer">
          <div className="row">
            <div className="col-md-6">
              <div className="f_widget">
                <img src={Logo} alt="Qubai Ai" className="logo mb-4" />
                <p className="mb-4" dangerouslySetInnerHTML={{ __html: t('fotter.para') }}s>
                
                </p>
                <div className="info_box d-flex justigy-content-start align-items-start">
                  <div className="info_small_box">
                    <p className="mb-0"> {t("fotter.email")}</p>
                    <a href="mailto:Info@qubyai.com">Info@qubyai.com</a>
                  </div>
                  <div className="info_small_box">
                    <p className="mb-0"> {t("fotter.address")}</p>
                    <a href="https://www.google.com/maps/place/650+Wellesley+St+E,+Toronto,+ON+M4X+1H9,+Canada/@43.6696161,-79.3629371,17z/data=!3m1!4b1!4m5!3m4!1s0x89d4cb5c769534af:0xf3ac50d65710928f!8m2!3d43.6696161!4d-79.3629371?entry=ttu">650 Wellesley Street East, Toronto, Canada</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-4">
              <div className="f_links d-flex justify-content-between">
                <div className="usrful_links d-inline d-flex flex-column">
                  <h5>{t("fotter.Platform")}</h5>
                  <div className="links-fotter"><a target="blank" className="d-inline-block"  href="https://www.qubyaigame.com/">{t("fotter.platform1.link1")}</a></div>
                  <p><a  target="blank" className="d-inline" href="http://www.qubychain.com/">{t("fotter.platform1.link2")}</a></p>
                  <p className="position-relative dapp"> 
                           <span className="position-absolute text-nowrap bg-white border z-1 comming-soon-text rounded p-1">{t("comingsoon")}</span>
                    <a    className="d-inline-block"  >{t("fotter.platform1.link3")} </a></p>
                </div>
                <div className="usrful_links">
                  <h5> {t("fotter.Company")}</h5>
                  <a target="blank" className="d-inline-block" href="mailto:Info@qubyai.com">{t("fotter.Company1.link1")}</a>
                  <p><a target="blank" className="d-inline"  href="https://quby-ai.gitbook.io/quby-ai/">{t("fotter.Company1.link2")}</a></p>
                  <p><a target="blank" className="d-inline"  href="https://quby-ai.gitbook.io/quby-ai/">{t("fotter.Company1.link3")}</a></p>
                </div>
                <div className="usrful_links">
                  <h5> {t("fotter.Resources")}</h5>
                  <a target="blank"  href="https://quby-ai.gitbook.io/quby-ai/">{t("fotter.Resources1.link1")}</a>
                  <p><a target="blank" className="d-inline"  href="https://quby-ai.gitbook.io/quby-ai/">{t("fotter.Resources1.link2")}</a></p>
                  <p><a target="blank"  className="d-inline"   href="https://docs.google.com/forms/d/e/1FAIpQLSd6NEUVBIf8wN4uQQADNfavZ3Q9_73BuLpceiqSA4hP1o6kHA/viewform">{t("fotter.Resources1.link3")}</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
