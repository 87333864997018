import React from "react";
import join from "../assets/images/join.gif";
import { useTranslation } from "react-i18next";
const Join = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="join_section">
      <div className="join_box">
        <div className="container" data-aos="zoom-in-down">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="heading_box  mb-5">
                <h1 className="mb-4 text-light">
                  {t("join.heading")}
                </h1>
                <p className="text-light">
                  {t("join.para1")}
                </p>
                <p className="text-light"> 
                  {t("join.para2")}
                </p>
                <div className="banner_btns d-lg-flex mt-4">
                  <a target="blank"
                    href="https://www.qubyaigame.com/"
                    className="bg-btn btn-hover">
                     {t("join.btn")}
                  </a>
                  <a target="blank"
                    href="https://twitter.com/QuByAigames"
                    className="outline-btn btn-hover">
                 {t("join.btn1")}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="heading_box text-center mb-5">
                <img src={join} alt="Team Member" className="w-100 mb-4" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Join;
