import React from "react";
import game2 from "../assets/images/gameintro2.gif";
import { useTranslation } from "react-i18next";
const Gameintro = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="container-fluid game_intro">
      <div className="container" data-aos="fade-right">
        <div className="row">
          <div className="col-6 col-md-6 col-lg-6 col-xl-6 into_image">
            <iframe
              src="https://www.youtube.com/embed/vma58azWjuo?autoplay=1&mute=1&loop=1&playlist=vma58azWjuo"
              title="Game Intro"
              height={300}
              className="Intro h-100 w-100 mb-5 pb-5"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen></iframe>
          </div>

          <div className="col-6 col-md-6 col-lg-6 col-xl-6 game_intro_box">
            <h2 className="mb-5">
              {t("game.heading")}
            </h2>
            <p className="text-white">
              {t("game.para1")}
            </p>
            <a target="blank" href="https://www.qubyaigame.com/" className="theme_btn_fill btn-hover d-inline-block">
              {t("game.btn")}
            </a>
          </div>
        </div>

        <div className="row game-sec-2">
          <div className="col-6 col-md-6 col-lg-6 col-xl-6 game_intro_box">
            <h2 className="mb-5">  {t("game.heading1")}</h2>
            <p className="text-white">
              {t("game.para1")}
            </p>
            <a
              href="https://qubychain.com" target="blank"
              className="theme_btn_fill2 btn-hover mt-3 d-inline-block">
             {t("game.btn1")}
            </a>
          </div>

          <div className="col-6 col-md-6 col-lg-6 col-xl-6 Game_into_image2 mt-5 mt-lg-0">
            <img src={game2} alt="Gamw Intro" className="Intro w-80" />
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default Gameintro;
