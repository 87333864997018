import React from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import linkedin from "../assets/images/linkedin.png";
import t1 from "../assets/images/t1.png";
import t2 from "../assets/images/t2.png";
import t3 from "../assets/images/t3.png";
import t4 from "../assets/images/t4.png";
const Team = () => {
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    // autoplay: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          dots: true,
          arrows: false,
          // autoplay: true,
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          // autoplay: true,
        },
      },

      {
        breakpoint: 576,
        settings: {
          arrows: false,
          dots: true,
          autoplay: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const { t } = useTranslation();
  return (
    <div className="team_section relative">
      <div className="team_box relative">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="heading_box text-center mb-5">
                <h6>{t("team.heading")}</h6>
                <h1 className="mb-4">{t("team.heading1")}</h1>
                <p dangerouslySetInnerHTML={{ __html: t("team.para") }}></p>
              </div>
            </div>
          </div>
          <div>
            <Slider {...settings} className="team_slider">
              <div>
                <div data-aos="flip-up">
                  <a
                    target="blank"
                    className=" text-decoration-none"
                    href="https://www.linkedin.com/in/paul-lois-623019222/"
                  >
                    <div className="team_small_box relative small_bg_team text-center mb-4">
                      <img
                        src={t1}
                        alt="Team Member"
                        className="team_img mb-5"
                      />
                      <h6>Paul Lois</h6>
                      <p className="mb-4">Product Manager</p>
                      <a
                        target="blank"
                        href="https://www.linkedin.com/in/paul-lois-623019222/"
                      >
                        <img
                          className="text-center mx-auto"
                          src={linkedin}
                          alt="linkedin"
                        />
                      </a>
                    </div>
                  </a>
                </div>
              </div>
              <div>
                <div data-aos="flip-up">
                  <a
                    target="blank"
                    className=" text-decoration-none"
                    href="https://www.linkedin.com/in/kareem-elhi-5ba83922a/"
                  >
                    <div className="team_small_box relative small_bg_team text-center mb-4">
                      <img
                        src={t2}
                        alt="Team Member"
                        className="team_img mb-5"
                      />
                      <h6>Kareem Elhi</h6>
                      <p className="mb-4">Full Stack Developer</p>
                      <a
                        target="blank"
                        href="https://www.linkedin.com/in/kareem-elhi-5ba83922a/"
                      >
                        <img
                          className="text-center mx-auto"
                          src={linkedin}
                          alt="linkedin"
                        />
                      </a>
                    </div>
                  </a>
                </div>
              </div>
              <div>
                <div data-aos="flip-up">
                  <a
                    target="blank"
                    className=" text-decoration-none"
                    href="https://www.linkedin.com/in/shed-b-delmassi-74393a229/"
                  >
                    <div className="team_small_box relative small_bg_team text-center mb-4">
                      <img
                        src={t3}
                        alt="Team Member"
                        className="team_img mb-5"
                      />
                      <h6>Shed B'Delmassi</h6>
                      <p className="mb-4">3D Designer/Video Game</p>
                      <a
                        target="blank"
                        href="https://www.linkedin.com/in/shed-b-delmassi-74393a229/"
                      >
                        <img
                          className="text-center mx-auto"
                          src={linkedin}
                          alt="linkedin"
                        />
                      </a>
                    </div>
                  </a>
                </div>
              </div>
              <div>
                <div data-aos="flip-up">
                  <a
                    target="blank"
                    className=" text-decoration-none"
                    href="https://www.linkedin.com/in/sarah-bena-a38a8522a/"
                  >
                    <div className="team_small_box relative small_bg_team text-center mb-4">
                      <img
                        src={t4}
                        alt="Team Member"
                        className="team_img mb-5"
                      />
                      <h6>Sarah Bena</h6>
                      <p className="mb-4">Marketing Manager</p>
                      <a
                        target="blank"
                        href="https://www.linkedin.com/in/sarah-bena-a38a8522a/"
                      >
                        <img
                          className="text-center mx-auto"
                          src={linkedin}
                          alt="linkedin"
                        />
                      </a>
                    </div>
                  </a>
                </div>
              </div>
            </Slider>
          </div>
        </div>

        <div className="container mt-5">
          <div className="row">
            <div className="col-12">
              <iframe
                className="team-video-iframe"
                src="https://www.youtube.com/embed/lFxqymolFQg?autoplay=1&loop=1&playlist=lFxqymolFQg&enablejsapi=1"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
