import ReactPaginate from "react-paginate";
import { useState } from "react";

const path = `${process.env.PUBLIC_URL}/nft`;

const loadNFTData = () => {
  return Array.from({ length: 1000 }, (_, index) => ({
    image: `${path}/${index + 1}.gif`,
  }));
};

const NftProducts = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 32;
  const nftData = loadNFTData(); // Load your NFT data
  const pageCount = Math.ceil(nftData.length / itemsPerPage);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const currentNfts = nftData.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  return (
    <div className="nft_product_box">
      <div className="container">
        <div className="row">
          {currentNfts.map((nft, index) => (
            <div className="col-md-3" key={index}>
              <div className="nft_box_product mb-4">
                <img src={nft.image} alt={nft.name} className="w-100" />
                <h5>{nft.name}</h5>
              </div>
            </div>
          ))}
        </div>
        <div className="col-md-12">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
          />
        </div>
      </div>
    </div>
  );
};

export default NftProducts;
