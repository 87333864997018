// import i18next from "i18next";
// import HttpBackend from "i18next-http-backend";
// import LanguageDetector from "i18next-browser-languagedetector";
// import { initReactI18next } from "react-i18next";

// const apiKey = "t732prq0heq8ed3OpAJdZA";
// const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`;

// i18next
//   .use(HttpBackend)
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     fallbackLng: "en",

//     ns: ["default"],
//     defaultNS: "default",

//     supportedLngs: [
//       "en",
//       "fr",
//       "nl",
//       "ru",
//       "ja",
//       "pt",
//       "ur",
//       "ar",
//       "fa",
//       "zh",
//       "ko",
//     ],

//     backend: {
//       loadPath: loadPath,
//     },
//   });

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector';
import translations from './translation';

i18n
.use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'eng',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection:{
      order:['queryString', 'cookie'],
      cache:['cookie']

    },
    resources: translations,
  });

export default i18n;
