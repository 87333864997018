import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import s4 from "../assets/images/twitter-new.svg";
import {
  Facebook,
  FooterInstagram,
  FooterYoutube,
  LinkedIn,
  Medium,
  Telegram,
  TikTok,
} from "../common/icon";
const Copyright = () => {
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="Copy_bg relative">
      <div className="container">
        <div className="copyright_footer mb-4">
          <div className="row">
            <div className="col-md-8">
              <div className="copy_txt d-flex justify-content-start align-items-center">
                <p className="mb-0">© 2023 {t("fotter.copyright")}</p>
                <div className="social-icons">
                  <a target="blank" href="https://t.me/QubyAi">
                    <Telegram />
                  </a>
                  <a
                    target="blank"
                    href="https://www.youtube.com/channel/UCCu1iYwpVTmYhxi62oP9enw"
                  >
                    <FooterYoutube />
                  </a>
                  <a target="blank" href="https://www.facebook.com/QuByAi/">
                    <Facebook />
                  </a>
                  <a
                    target="blank"
                    className="invert-1"
                    href="https://twitter.com/QuByAigames"
                  >
                    <img src={s4} alt="Social Icon" />
                  </a>
                  <a target="blank" href="https://www.instagram.com/quby_ai">
                    <FooterInstagram />
                  </a>
                  <a target="blank" href="https://www.tiktok.com/@quby.ai.game">
                    <TikTok />
                  </a>
                  <a target="blank" href="https://medium.com/@qubyai.meme">
                    <Medium />
                  </a>
                  <a
                    target="blank"
                    href="https://www.linkedin.com/company/qubyaigame"
                  >
                    <LinkedIn />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="use_links text-end flex ">
                <a
                  className="position-relative"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  {t("fotter.privacy")}
                </a>
                <a
                  className="position-relative ms-3"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalCookies"
                >
                  {t("fotter.Cookies")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h1
                  className="modal-title fs-5 policy-heading"
                  id="exampleModalLabel"
                >
                  Privacy Policy
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <h4 className="policy-heading">Introduction</h4>
                <p className="policy-content">
                  At QuByAiGame.com, QuByAi.com, QuByChain.com, we value your
                  privacy. This privacy policy explains what information we
                  collect from you and how we use it. We will never release your
                  private information to third parties.
                </p>
                <h4 className="policy-heading">What Data We Collect</h4>
                <ul className="policy-content">
                  <li>
                    We might collect some information to help us improve our
                    services:
                  </li>
                  <li>Your Name and Email: if you choose to provide them.</li>
                  <li>
                    Technical Information: like your IP address and browser
                    type.
                  </li>
                  <li>Crypto Information: such as your wallet addresses. </li>
                </ul>
                <h4 className="policy-heading">How We Use Your Data</h4>
                <ul className="policy-content">
                  <li>We use this information to:</li>
                  <li>Make our website work for you.</li>
                  <li>Keep everything safe and secure.</li>
                  <li>
                    Help us understand what you need so we can make our website
                    better.
                  </li>
                </ul>
                <h4 className="policy-heading">Keeping Your Data Safe</h4>
                <p className="policy-content">
                  We take special care to keep your information safe:
                </p>
                <h4 className="policy-heading">Holding onto Your Data</h4>
                <p className="policy-content">
                  We only keep your information for as long as we need it. After
                  that, we delete it.
                </p>
                <h4 className="policy-heading">No Third Parties</h4>
                <p className="policy-content">
                  We promise not to give your information to any third-party
                  companies. Your privacy is important to us.
                </p>
                <h4 className="policy-heading">Talk to Us</h4>
                <p className="policy-content">
                  If you have any questions or need help with anything, you can
                  email us at Contact@qubyai.com.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div
          className="modal fade"
          id="exampleModalCookies"
          tabIndex={-1}
          aria-labelledby="exampleModalLabelCookies"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h1
                  className="modal-title fs-5 policy-heading"
                  id="exampleModalLabel"
                >
                  Cookies
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <p className="policy-content">
                  At QuByAiGame.com, QuByAi.com, QuByChain.com, we are committed
                  to enhancing your experience within the decentralized and
                  crypto-centric environment of our website. By continuing to
                  navigate our site, you consent to our use of cookies.
                </p>
                <h4 className="policy-heading">What are cookies?</h4>
                <p className="policy-content">
                  Cookies are tiny text files stored on your device when you
                  access our website. They allow us to remember specific
                  information about your visit, like your preferred settings,
                  and enable us to optimize your experience within the crypto
                  space.
                </p>
                <h4 className="policy-heading">How we use cookies?</h4>
                <p className="policy-content">
                  <em>Essential Cookies:</em> These cookies ensure that our
                  website functions properly. Without them, certain
                  blockchain-related features may not operate as intended.
                </p>
                <p className="policy-cotent">
                  <em>Performance Cookies:</em> These cookies help us analyze
                  how users interact with our crypto features, enabling us to
                  make improvements to the site’s functionality.
                </p>

                <p className="policy-content">
                  <em>Third-Party Cookies:</em> We may use third-party cookies
                  to offer additional crypto-related services, such as embedded
                  exchange widgets or social media plugins. These cookies are
                  controlled by respective third-party providers and may track
                  your activity across other sites.
                </p>

                <p>
                  <em>Crypto Cookies:</em> These cookies enable us to provide
                  personalized cryptocurrency experiences, such as remembering
                  your wallet address or preferred tokens.
                </p>

                <h4 className="policy-heading">Managing Cookies</h4>
                <p className="policy-content">
                  You can manage or delete cookies using your browser settings.
                  Disabling essential cookies may affect the functionality of
                  certain crypto-related features on the site. <br />
                  If you have any questions or concerns about our use of cookies
                  or this policy within the crypto context, please contact us at
                  Contact@QuByAi.com <br />
                  By engaging with MemeKombatToken.com, you agree to our use of
                  cookies in accordance with this Cookies Policy.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Copyright;
