import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../LanguageContext";
import HeroVideo from "../assets/videos/hero.mp4";
const Hero = () => {
  const { t, i18n } = useTranslation();
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  return (
    <>
      <div className="hero-main">
        <video
          src={HeroVideo}
          className="w-100 hero-video object-fit-cover"
          autoPlay
          loop
          muted
        ></video>
        <div className="hero-text">
          <div className="banner_content" data-aos="zoom-in">
            <h6>{t("hero.heading1")}</h6>
            <h1>
              {t("hero.heading2")} <br />
              {t("hero.heading3")}
            </h1>
            <div
              className="banner_btns
               mt-4 flex-column flex-md-row d-flex"
            >
              <a
                target="blank"
                href="https://www.qubyaigame.com/"
                className="bg-btn btn-hover"
              >
                {t("hero.btn1")}
              </a>
              <a
                target="blank"
                href="https://app.hel.io/pay/65ec1c8c6c9a0b22b1f07d67"
                className="outline-btn btn-hover"
              >
                {t("hero.btn2")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
