import React from "react";
import user from "../assets/images/user.svg";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Newsletter = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState("");
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(false);

  const addSubscriber = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        "https://qubyai-server.vercel.app/subscribe",
        { email: email }
      );
      if (response && response.status === 200) {
        toast.success("Subscribe Successfully");
        setEmail("");
      }
    } catch (error) {
      toast.error("Subscription failed");
      console.error("Subscription error:", error);
    }
    setLoading(false);
  };

  return (
    <div className="newsletter_bg relative">
      <div className="news_letter mb-4">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="news_heading">
                <h4>
                {t("news.heading1")} <br /> {t("news.heading2")}
                </h4>
              </div>
            </div>
            <div className="col-md-5">
              <div className="news_form relative">
                <form
                  className="d-flex justigy-content-end align-items-center"
                  onSubmit={(e) => addSubscriber(e)}>
                  <input
                    value={email}
                    type="email"
                    placeholder={` ${t("news.placeholder")}`}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={loading}
                  />
                  <img src={user} alt="user" className="user_imgg" />
                  <button className="btn-hover">{t("news.Subscribe")}</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
