import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import flagUs from "../assets/svg/us.svg";
import flagFr from "../assets/svg/fr.svg";
import flagCn from "../assets/svg/cn.svg";
import flagNl from "../assets/svg/nl.svg";
import flagRu from "../assets/svg/ru.svg";
import flagJp from "../assets/svg/jp.svg";
import flagPt from "../assets/svg/pt.svg";
import flagEs from "../assets/svg/es.svg";
import flagDe from "../assets/svg/de.svg";
import flagIt from "../assets/svg/it.svg";
import flagKr from "../assets/svg/kr.svg";
import flagVn from "../assets/svg/vn.svg";
import flagId from "../assets/svg/id.svg";
import flagTu from "../assets/png/turkey.png";

const IpAddress = () => {
  const { t } = useTranslation();
  const [ipAddress, setIpAddress] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [language, setLanguage] = useState("eng"); // Default language
  const [flag, setFlag] = useState(flagUs); // Default flag
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const handleLanguageChange = (selectedLanguage) => {
    let selectedFlag;
    if (selectedLanguage === "eng") {
      selectedFlag = flagUs;
    } else if (selectedLanguage === "fra") {
      selectedFlag = flagFr;
    } else if (selectedLanguage === "chi") {
      selectedFlag = flagCn;
    } else if (selectedLanguage === "nld") {
      selectedFlag = flagNl;
    } else if (selectedLanguage === "rus") {
      selectedFlag = flagRu;
    } else if (selectedLanguage === "jpn") {
      selectedFlag = flagJp;
    } else if (selectedLanguage === "prt") {
      selectedFlag = flagPt;
    } else if (selectedLanguage === "esp") {
      selectedFlag = flagEs;
    } else if (selectedLanguage === "deu") {
      selectedFlag = flagDe;
    } else if (selectedLanguage === "ita") {
      selectedFlag = flagIt;
    } else if (selectedLanguage === "kor") {
      selectedFlag = flagKr;
    } else if (selectedLanguage === "vnm") {
      selectedFlag = flagVn;
    } else if (selectedLanguage === "idn") {
      selectedFlag = flagId;
    } else if (selectedLanguage === "tur") {
      selectedFlag = flagTu;
    }
    i18n.changeLanguage(selectedLanguage);
    setLanguage(selectedLanguage);
    setFlag(selectedFlag);
    setIsDropdownOpen(false);
  };

  const optionsArray = [
    { key: "eng", value: "English", flag: flagUs },
    { key: "fra", value: "French", flag: flagFr },
    { key: "chi", value: "Chinese", flag: flagCn },
    { key: "kor", value: "Korean", flag: flagKr },
    { key: "nld", value: "Dutch", flag: flagNl },
    { key: "rus", value: "Russian", flag: flagRu },
    { key: "jpn", value: "Japanese", flag: flagJp },
    { key: "prt", value: "Portuguese", flag: flagPt },
    { key: "tur", value: "Turkish", flag: flagTu },
    { key: "deu", value: "German", flag: flagDe },
    { key: "ita", value: "Italian", flag: flagIt },
    { key: "vnm", value: "Vietnamese", flag: flagVn },
    { key: "idn", value: "Indonesian", flag: flagId },

    // Add more options as needed
  ];

  useEffect(() => {
    const fetchIpAndCountry = async () => {
      try {
        // Fetch IP address
        const ipResponse = await axios.get(
          "https://api64.ipify.org?format=json"
        );
        setIpAddress(ipResponse.data.ip);

        // Fetch country based on IP address
        const countryResponse = await axios.get(
          `https://ipapi.co/${ipResponse.data.ip}/country/`
        );
        setCountry(countryResponse.data);

        // Set language and flag based on the country
        if (countryResponse.data === "US") {
          handleLanguageChange("eng");
        } else if (countryResponse.data === "FR") {
          handleLanguageChange("fra");
        } else if (countryResponse.data === "CN") {
          handleLanguageChange("chi");
        } else if (countryResponse.data === "NL") {
          handleLanguageChange("nld");
        } else if (countryResponse.data === "RU") {
          handleLanguageChange("rus");
        } else if (countryResponse.data === "JP") {
          handleLanguageChange("jpn");
        } else if (countryResponse.data === "PT") {
          handleLanguageChange("prt");
        } else if (countryResponse.data === "TR") {
          handleLanguageChange("tur");
        } else if (countryResponse.data === "DE") {
          handleLanguageChange("deu");
        } else if (countryResponse.data === "IT") {
          handleLanguageChange("ita");
        } else if (countryResponse.data === "KR") {
          handleLanguageChange("kor");
        } else if (countryResponse.data === "ID") {
          handleLanguageChange("idn");
        } else if (countryResponse.data === "VN") {
          handleLanguageChange("vnm");
        } else {
          handleLanguageChange("eng");
        }

        // Fetch city based on IP address
        const cityResponse = await axios.get(
          `https://ipapi.co/${ipResponse.data.ip}/city/`
        );
        setCity(cityResponse.data);
        setIsLoaded(true);
      } catch (error) {
        console.error("Error fetching IP, country, or city:", error);
      }
    };

    fetchIpAndCountry();
  }, []);

  return (
    <div
      className="relative inline-block text-left"
      onMouseLeave={() => setIsDropdownOpen(false)}
    >
      <div>
        <span
          className="rounded-md "
          onMouseEnter={() => setIsDropdownOpen(true)}
        >
          <button
            type="button"
            className="language_dropdown flex d-flex sm:gap-2 align-items-center bg-transparent border-0 justify-center w-100 rounded-md   px-4 py-2 text-sm font-medium text-gray-700  focus:outline-none"
            id="options-menu"
            aria-haspopup="true"
            aria-expanded="true"
          >
            <img src={flag} alt="Flag" className="w-8 h-8 sm:h-10 sm:w-10  " />
            <span className="uppercase font-vastagoRegular font-semibold w-5 mx-2 text-white text-uppercase">
              {t(language)}
            </span>
            <svg
              className="w-100"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M16.6925 7.94217L10.4425 14.1922C10.3845 14.2503 10.3156 14.2964 10.2397 14.3278C10.1638 14.3593 10.0825 14.3755 10.0003 14.3755C9.91821 14.3755 9.83688 14.3593 9.76101 14.3278C9.68514 14.2964 9.61621 14.2503 9.55816 14.1922L3.30816 7.94217C3.19088 7.82489 3.125 7.66583 3.125 7.49998C3.125 7.33413 3.19088 7.17507 3.30816 7.05779C3.42544 6.94052 3.5845 6.87463 3.75035 6.87463C3.9162 6.87463 4.07526 6.94052 4.19254 7.05779L10.0003 12.8664L15.8082 7.05779C15.8662 6.99972 15.9352 6.95366 16.011 6.92224C16.0869 6.89081 16.1682 6.87463 16.2503 6.87463C16.3325 6.87463 16.4138 6.89081 16.4897 6.92224C16.5655 6.95366 16.6345 6.99972 16.6925 7.05779C16.7506 7.11586 16.7967 7.1848 16.8281 7.26067C16.8595 7.33654 16.8757 7.41786 16.8757 7.49998C16.8757 7.5821 16.8595 7.66342 16.8281 7.73929C16.7967 7.81516 16.7506 7.8841 16.6925 7.94217Z"
                fill="#fff"
              />
            </svg>
          </button>
        </span>
      </div>

      {isDropdownOpen && (
        <div
          className="top-14 absolute left-6 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none language_dropdown_content"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
          onMouseEnter={() => setIsDropdownOpen(true)}
          onMouseLeave={() => setIsDropdownOpen(false)}
        >
          <p></p>
          <div className="py-1" role="none">
            {optionsArray.map((option) => (
              <div
                key={option.key}
                className="d-flex px-2 cursor-pointer py-1 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
                onClick={() => handleLanguageChange(option.key)}
              >
                <img
                  src={option.flag}
                  alt={`${option.key} flag`}
                  className="h-5 w-5 mr-1 rounded-full object-cover country-img"
                />
                <span className="ms-2">{t(option.value)}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default IpAddress;
