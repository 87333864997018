import React from "react";
import join from "../assets/images/join.gif";
import { useTranslation } from "react-i18next";
const Faq = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="join_section relative">
      <div className="Faq_box relative">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8 mx-auto">
              <div className="heading_box text-center  mb-5">
                <h6 className="">{t("faq.heading")}</h6>
                <h1 className="mb-4">{t("faq.faq")}</h1>
              </div>

              <div className="accordion" id="accordionExamplemine">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne">
                      {t("faqhead.head1")}
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body" dangerouslySetInnerHTML={{ __html: t('faqbody.head1') }}>
                    
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo">
                   {t("faqhead.head2")}
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      
                      {t("faqbody.head2")}
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree">
                       {t("faqhead.head3")}
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                     
                      {t("faqbody.head3")}
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour">
                      {t("faqhead.head4")}
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                   
                      {t("faqbody.head4")}
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFive">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive">
                      {t("faqhead.head5")}
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <ol>
                        <li>
                         
                          {t("faqbody.head5.one")}
                        </li>
                        <li dangerouslySetInnerHTML={{ __html: t("faqbody.head5.two") }}>
            
                       
                        </li>
                        <li>
                        
                          {t("faqbody.head5.three")}
                        </li>
                        <li>
                       
                          {t("faqbody.head5.four")}
                        </li>
                        <li>
                       
                          {t("faqbody.head5.five")}
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
