import React from "react";

const Presale = () => {
  return (
    <>
      <section className="presale-wrapper">
        <a
          aria-label="Click here for presale"
          target="_blank"
          href="https://app.hel.io/pay/65ec1c8c6c9a0b22b1f07d67"
        >
          Click here
        </a>
      </section>
    </>
  );
};

export default Presale;
