import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CountUp from "react-countup";
import { LanguageContext } from "../LanguageContext";
const Analytics = () => {
  const endCount = 101000000000000; // Final value to count up to
  const [startCount, setStartCount] = useState(0);
  const { t } = useTranslation();
  const countUpRef = useRef(null);
  const { language } = React.useContext(LanguageContext);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Change this threshold according to when you want the animation to start
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setStartCount(endCount); // Set the start value to trigger the CountUp animation
          observer.unobserve(entry.target); // Stop observing after triggering animation
        }
      });
    }, options);

    if (countUpRef.current) {
      observer.observe(countUpRef.current);
    }

    return () => {
      if (countUpRef.current) {
        observer.unobserve(countUpRef.current);
      }
    };
  }, [endCount]);

  return (
    <div className="anayltic" style={{ marginTop: "100px" }} data-aos="zoom-in">
      <div className="counter_box">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="analytic_box relative anlytics_bl mb-4">
                <h1 className="mb-0">0%</h1>
                <h6>{t("BuyTax")}</h6>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="analytic_box relative anlytics_bl mb-4">
                <h1 className="mb-0">0%</h1>
                <h6>{t("SellTax")}</h6>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="analytic_box relative anlytics_bl mb-4">
                <h1 className="mb-0">100%</h1>
                <h6>{t("Community")}</h6>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="analytic_box mb-4 text-center" ref={countUpRef}>
                <h1>
                  <CountUp start={0} end={startCount} duration={3} separator="," />{/* Render the CountUp component */}
                </h1>
                <h6>{t("TotalSupply")}</h6> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;



 

// components/Analytics.js


 