import React from "react";

import nfthero from "../assets/images/nfthero.gif";
import opensea from "../assets/images/opensea.png";
import { useTranslation } from "react-i18next";

const Heronft = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="Heronft_bg_box">
      <div className="Heronft_bg"></div>
      <div className="nft_banner relative">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="nft_banner_content text-center">
                <img src={nfthero} alt="Cloud" className="nfthero" />
                <h6>{t("nft.heading")}</h6>
                <h1>{t("nft.subheading")}</h1>
                <p className="mt-3 mb-4">{t("nft.para")}</p>
                <img
                  src={opensea}
                  alt="Cloud"
                  className="opensea"
                  width="332px"
                  height="89px"
                />
                <div className="banner_btns mt-5 text-center">
                  <p className="position-relative dapp">
                    <span className="position-absolute text-nowrap bg-white border z-1 comming-soon-text rounded p-1">
                      Comming-Soon
                    </span>
                    <a href="#" className="bg-btn">
                      {t("hero.btn2")}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Heronft;
