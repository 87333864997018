import React from "react";
import Analytics from "../../Components/Analytics";
import Copyright from "../../Components/Copyright";
import Faq from "../../Components/Faq";
import Footer from "../../Components/Footer";
import Gameintro from "../../Components/Gameintro";
import Header from "../../Components/Header";
import Hero from "../../Components/Hero";
import Introduction from "../../Components/Introduction";
import Join from "../../Components/Join";
import Newsletter from "../../Components/Newsletter";
import Presale from "../../Components/Presale";
import Roadmap from "../../Components/Roadmap";
import Team from "../../Components/Team";
import Tokenomic from "../../Components/Tokenomic";
import "./Home.css";

const Home = () => {
  return (
    <>
      <main className="overflow-hidden">
        <Header />
        <Hero />
        <Analytics />
        <Presale />
        <Introduction />
        <Gameintro />
        <Roadmap />
        <Tokenomic />
        <Team />
        <Join />
        <Faq />
        <Newsletter />
        <Footer />
        <Copyright />
      </main>
    </>
  );
};

export default Home;
