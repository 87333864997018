import Logo from "../assets/images/logo.png";
import LogoG from "../assets/images/logo.gif";
import { BiLogoTelegram } from "react-icons/bi";
import { BsYoutube, BsTwitter } from "react-icons/bs";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../LanguageContext";
import { publicIpv4 } from "public-ip";
import axios from "axios";
import { CircleFlag } from "react-circle-flags";
import Select from "react-select";
import Twitter from "../assets/images/twitter-new.svg";
import IpAddress from "./IpAdress";

const Header = () => {
  const navigate = useNavigate();

  // const { language, handleLanguageChange } = useContext(LanguageContext);

  const { t, i18n } = useTranslation();

  const langOption = [
    {
      label: "English",
      value: "En",
      ccode: "US",
    },
    {
      label: "French",
      value: "fr",
      ccode: "fr",
    },
    {
      label: "Chinese",
      value: "zh",
      ccode: "cn",
    },
    {
      label: "Korea",
      value: "ko",
      ccode: "kr",
    },

    {
      label: "Dutch",
      value: "nl",
      ccode: "nl",
    },
    {
      label: "Russian",
      value: "ru",
      ccode: "ru",
    },
    {
      label: "Japanese",
      value: "ja",
      ccode: "jp",
    },
    {
      label: "Portuguese",
      value: "pt",
      ccode: "pt",
    },
  ].map((option) => ({
    ...option,
    label: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <CircleFlag countryCode={option.ccode.toLowerCase()} height="20" />
        <span style={{ marginLeft: 10 }}>{option.label}</span>
      </div>
    ),
  }));

  // const handleChange = (option) => {
  //   handleLanguageChange(option.value);
  //   i18n.changeLanguage(option.value);
  //   navigate(`/home/${option.value}`);
  // };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent",
      hover: {
        borderColor: "lightgray",
      },
      color: "white !important",
      border: state.isFocused ? "none" : "none",
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black",
      fontSize: 10,
    }),

    singleValue: (provided, state) => ({
      ...provided,
      color: "white",
    }),
  };

  // useEffect(() => {
  //   ipData();
  // }, []);

  // const ipData = async () => {
  //   const ip = await publicIpv4();

  //   console.log("IP", ip);

  //   const response = await axios.get(
  //     `http://api.ipapi.com/api/${ip}?access_key=c0c8fa3a5860ef329d60b66334f2e78f`
  //   );

  //   if (response && response.status === 200) {
  //     console.log("Data IP", response.data);
  //   }
  // };

  return (
    <div className="header_bg">
      <nav className="navbar container navbar-expand-lg">
        <Link to="/">
          <a className="navbar-brand"  >
            <img src={LogoG} alt="" className="logoG" />
            <img src={Logo} alt="Qubai Ai" className="logo" />
          </a>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 hero-qubyai py-lg-0 py-4">
            {/* <li className="nav-item">
              <Link className="nav-link" to="/">
                {t("Home")}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="">
                {t("About")}
              </Link>
            </li> */}
            <li className="nav-item">
              <a
                className="nav-link"
                target="blank"
                href="https://www.qubyaigame.com/"
              >
                {t("nav.link1")}
              </a>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/nft">
                {t("nav.link2")}
              </Link>
            </li>
            <li className="nav-item">
              <a target="blank" className="nav-link" href="http://www.qubychain.com/">
                {t("nav.link3")}
              </a>
            </li>
          </ul>
          <div className="d-flex align-items-center right_bars">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 align-items-center">
              <li className="nav-icons">
                <a className="nav-link" href="https://t.me/QubyAi">
                  <BiLogoTelegram
                    size={25}
                    color="#ffff"
                    className="h-telegram"
                  />
                </a>
              </li>
              <li className="nav-icons">
                <a target="blank"
                  className="nav-link"
                  href="https://www.youtube.com/channel/UCCu1iYwpVTmYhxi62oP9enw"
                >
                  <BsYoutube size={25} color="#ffff" className="h-youtube" />
                </a>
              </li>
              <li className="nav-icons">
                <a target="blank"  className="nav-link" href="https://twitter.com/QuByAigames">
                  <img src={Twitter} alt="Twitter" />
                </a>
              </li>
              <li className="nav-icons pt-lg-0 pt-3">
                <a target="blank" className="butn_stack btn-hover" href="https://www.qubychain.com/staking">
                  {t("nav.btn")}
                </a>
              </li>
              <li className="pt-lg-0 pt-3">
                <IpAddress />
              </li>
              &nbsp; &nbsp;
              {/* <li className="nav-item dropdown">
                <Select
                  value={langOption.find((o) => o.value === language)}
                  defaultValue={langOption[0]}
                  styles={customStyles}
                  className="lng-h"
                  onChange={handleChange}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  options={langOption}
                />
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
