import React from 'react'
import Header from "../../Components/Header";
import Heronft from "../../Components/Heronft";
import NftProducts from "../../Components/NftProducts";
import Join from "../../Components/Join";
import Faq from "../../Components/Faq";
import Newsletter from "../../Components/Newsletter";
import Footer from "../../Components/Footer";
import Copyright from "../../Components/Copyright";
import "./Nft.css";
const NFT = () => {
  return (
    <div className='NFT_PAGE'>
      <Header/>   
      <Heronft />
      <NftProducts />
      <Join />
      <Faq />
      <Newsletter />
      <Footer />
      <Copyright />
    </div>
  )
}

export default NFT