import Intro from "../assets/images/intro-the-qubyai.gif";
import React from "react";
import { useTranslation } from "react-i18next";

const Introduction = () => {
  const { t } = useTranslation();
  return (
    <div className="introduction">
      <div className="intro_box">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="intro_box mb-4" data-aos="fade-down-right">
                <h6>{t("intro.ai")}</h6>

                <h2
                  className="mb-5"
                  dangerouslySetInnerHTML={{ __html: t("intro.heading") }}
                ></h2>

                <p dangerouslySetInnerHTML={{ __html: t("intro.para1") }}></p>
                <p>{t("intro.para2")}</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="into_image" data-aos="fade-up-left">
                <img
                  src={Intro}
                  className="w-100 hero-video object-fit-cover"
                  alt="Intro"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Introduction;
