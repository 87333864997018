import React from "react";
import roadmap from "../assets/images/roadmap.gif";
import arrow from "../assets/images/roadarrow.png";
import { useTranslation } from "react-i18next";
const Roadmap = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="road_map_section">
      <div className="road_box">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="heading_box text-center mb-5">
                <img
                  src={roadmap}
                  alt="Roadmap"
                  width="340px"
                  className="mb-2"
                />
                <h6>{t("roadmap.heading")} </h6>
                <h1>{t("roadmap.heading1")}</h1>
              </div>
            </div>
            <div className="road_steps col-md-10 mx-auto">
              <div className="road_step mb-5 d-flex justify-content-between">
                <h3 className="relative bars active">01</h3>
                <h3 className="relative bars">02</h3>
                <h3 className="relative bars">03</h3>
                <h3 className="relative">04</h3>
              </div>
            </div>
            <div className="col-md-3">
              <div className="road_deatils active mb-4">
                <h2 className="mb-4">{t("roadmap.phase")} 1</h2>
                <p className="mb-2">
                  <img src={arrow} alt="arrow" /> {t("roadmap.phase1.line1")}
                </p>
                <p className="mb-2">
                  <img src={arrow} alt="arrow" /> {t("roadmap.phase1.line2")}
                </p>
                <p className="mb-2">
                  <img src={arrow} alt="arrow" /> {t("roadmap.phase1.line3")}
                </p>
                <p className="mb-2">
                  <img src={arrow} alt="arrow" /> {t("roadmap.phase1.line4")}
                </p>
                <p className="mb-2">
                  <img src={arrow} alt="arrow" /> {t("roadmap.phase1.line5")}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="road_deatils mb-4">
                <h2 className="mb-4">{t("roadmap.phase")} 2</h2>
                <p className="mb-2">
                  <img src={arrow} alt="arrow" /> {t("roadmap.phase2.line1")}
                </p>
                <p className="mb-2">
                  <img src={arrow} alt="arrow" /> {t("roadmap.phase2.line2")}
                </p>
                <p className="mb-2">
                  <img src={arrow} alt="arrow" />{t("roadmap.phase2.line3")}
                </p>
                <p className="mb-2">
                  <img src={arrow} alt="arrow" /> {t("roadmap.phase2.line4")}
                </p>
                <p className="mb-2">
                  <img src={arrow} alt="arrow" /> {t("roadmap.phase2.line5")}
                </p>
               
                <p className="mb-2">
                  <img src={arrow} alt="arrow" /> {t("roadmap.phase2.line7")}
                </p>
                <p className="mb-2">
                  <img src={arrow} alt="arrow" /> {t("roadmap.phase2.line8")}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="road_deatils mb-4">
                <h2 className="mb-4">{t("roadmap.phase")} 3</h2>
                <p className="mb-2">
                  <img src={arrow} alt="arrow" />
                  {t("roadmap.phase3.line1")}
                </p>
                <p className="mb-2">
                  <img src={arrow} alt="arrow" />    {t("roadmap.phase3.line2")}
                </p>
                <p className="mb-2">
                  <img src={arrow} alt="arrow" />     {t("roadmap.phase3.line3")}
                </p>
                <p className="mb-2">
                  <img src={arrow} alt="arrow" />     {t("roadmap.phase3.line4")}
                </p>
                <p className="mb-2">
                  <img src={arrow} alt="arrow" />     {t("roadmap.phase3.line5")}
                </p>
                <p className="mb-2">
                  <img src={arrow} alt="arrow" />   {t("roadmap.phase3.line7")}
                </p>
                <p className="mb-2">
                  <img src={arrow} alt="arrow" />      {t("roadmap.phase3.line8")}
                </p>
                <p className="mb-2">
                  <img src={arrow} alt="arrow" />      {t("roadmap.phase2.line6")}
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="road_deatils mb-4">
                <h2 className="mb-4">{t("roadmap.phase")} 4</h2>
                <p className="mb-2">
                  <img src={arrow} alt="arrow" />  {t("roadmap.phase3.line6")}
                </p>
                <p className="mb-2">
                  <img src={arrow} alt="arrow" /> {t("roadmap.phase4.line1")}
                </p>
                <p className="mb-2">
                  <img src={arrow} alt="arrow" /> {t("roadmap.phase4.line2")}
                </p>
                <p className="mb-2">
                  <img src={arrow} alt="arrow" />
                  {t("roadmap.phase4.line3")}
                </p>
                <p className="mb-2">
                  <img src={arrow} alt="arrow" /> {t("roadmap.phase4.line4")}
                </p>
                <p className="mb-2">
                  <img src={arrow} alt="arrow" /> {t("roadmap.phase4.line5")}
                </p>
                <p className="mb-2">
                  <img src={arrow} alt="arrow" /> {t("roadmap.phase4.line6")}
                </p>
                <p className="mb-2">
                  <img src={arrow} alt="arrow" /> {t("roadmap.phase4.line7")}
                </p>
                <p className="mb-2">
                  <img src={arrow} alt="arrow" /> {t("roadmap.phase4.line8")}
                </p>
                <p className="mb-2">
                  <img src={arrow} alt="arrow" /> {t("roadmap.phase4.line9")}
                </p>
                <p className="mb-2">
                  <img src={arrow} alt="arrow" /> {t("roadmap.phase4.line10")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
