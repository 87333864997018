import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Home from "./Pages/Home/Home";
import NFT from "./Pages/Nft/NFT";
import { Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AOS from "aos";
import "aos/dist/aos.js";
import "./i18n.js";
import { useEffect } from "react";
function App() {
  useEffect(() => {
    AOS.init({
      delay: 100,
      duration: 600,
    });
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="App">
        <Routes>
          {/* <Route path="/" element={<Navigate to={"/"} />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/home/:lng" element={<Home />} />
          <Route path="/nft" element={<NFT />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
