export const translations = {
  eng: {
    translation: {
      comingsoon: "coming soon",
      nav: {
        btn: "Staking",
        link1: "QuBy Game",
        link2: "NFT",
        link3: "QuByChain",
      },
      hero: {
        heading1: "Advancing Memecoin:",
        heading2: "Unleash the ",
        heading3: " Power of QuBy Ai",
        btn1: "Play Now",
        btn2: "Buy Now",
      },
      nft: {
        heading: "Our NFT’s",
        subheading: "1000 QuBy Ai NFTs Collection",
        para: "Explore the exclusive 1000 QuBy Ai NFTs, uniquely designed to embody the project's innovative essence. Each NFT, a symbol of creativity and evolution, enriches the QuBy Ai universe, allowing collectors to become integral parts of its transformative journey.",
      },
      news: {
        heading1: "  Join our newsletter to keep ",
        heading2: " up to date with us!",
        placeholder: "Enter your Emial",
        Subscribe: "Subscribe",
      },

      BuyTax: "Buy Tax",
      SellTax: "Sell Tax",
      Community: "Community",
      TotalSupply: "Total Supply",
      connect: "Connect wallet (Coming Soon)",
      bsc: "Buy On BSC",
      buy: "How To Buy?",
      presale: {
        heading1: "Presale",
        btn: "Whitelist",
        raised: "Raised : Presale Not Started",
        choose: "Choose Your Currency ",
        pay: "you Pay",
        recive: "you receive",
        heading2: "Play, Stake & Earn With QuBy Ai",
        para1:
          "Discover QuBy Ai, the cutting-edge gaming platform with AI technology for passionate",
        para2:
          "gamers, offering dynamic betting and passive staking for rich rewards.",
      },
      intro: {
        ai: "Introducing The QuBy Ai",
        btn: "Whitelist",
        heading:
          "The Fusion Of QuBy, The Iconic Chinese Character, With Ai Chatgpt",
        para1: `Introducing <b>QuBy Ai</b>, a groundbreaking Solana blockchain project merging Memecoin, Ai Game, and NFTs for innovative and cost-effective gaming.`,
        para2:
          "  We burn $QYAI tokens for efficiency and ensure code transparency. Join the QuBy Ai revolution!",
      },
      game: {
        btn: "Play Now",
        heading: "Redefining Online Gaming in the Web 3.0 Era.",
        btn1: " Visit Now",
        heading1: "From Memecoin to Blockchain Ecosystem.",
        para1: `QuBy Ai is a groundbreaking Web 3.0 online game, free to play and
        loaded with opportunities for players to earn $QYAI tokens.
        Harnessing the power of blockchain, the game redefines user
        experience by blending engaging gameplay with rewarding outcomes,
        marking a significant evolution in the gaming industry.`,
        para2:
          "QuBy Ai is a transformative force in the meme economy, blending  ChatGPT, Gamefi, NFT development, and Web 3.0 environments. It's not stopping there—it's establishing its own blockchain for play-to-earn experiences and community decision-making. <br /> Get ready to unlock opportunities and witness QuBy Ai's transformation.",
      },
      teko: {
        heading: "Tokenomics",
        subheading: "A Rebust Burn Mechanism  ",
        para: "  QuBy Ai implements a <b>10% burn</b> on every token sale, promoting scarcity and enhancing <br /> token value within its ecosystem.",
      },
      team: {
        heading: "Our Team",
        heading1: "Meet QuBy Ai Team ",
        para: " QuBy's team comprises accomplished artists, developers, and  experts in marketing <br />  and blockchain, each committed to steering the project toward  its ambitious goals through innovative <br /> technologies and  strategies.",
      },
      faq: {
        heading: "Questions & Answers ",
        faq: "Frequently Asked Questions ",
      },

      faqhead: {
        head1: "What is the QuBy Ai all about? ",
        head2: "   What makes QuBy Ai to be more efficient?  ",
        head3: "Why should i buy QuByAi? ",
        head4: " How many NFTs will be minted? ",
        head5: "How can I play QuBy Ai game?  ",
      },
      faqbody: {
        head1: `  QuBy Ai is a project that combines a meme coin token, a free video game on <a target="blank" href="https://www.QUBYAIGAME.com/">www.QUBYAIGAME.com</a>, and thedevelopment of an innovative blockchain called &nbsp; <a target="blank" href="http://www.qubychain.com/">www.QuByChain.com</a>,  offering a unique blend of cryptocurrency, entertainment,  and blockchain technology.`,
        head2:
          "QuBy Ai is more efficient due to its use of the ChatGPT  algorithm for game code and smart contract creation and  its token burning mechanism that reduces supply when  tokens are sold, enhancing overall system efficiency.",
        head3:
          " Play-to-Earn; QuByAi is the primary token in the QuBy Ai ecosystem, allowing users to participate in play-to-earn  activities within the QuBy Ai Game. This means you can  earn rewards and incentives by playing the game, providing  an opportunity to benefit from your gaming experience.  Community Involvement; QuByAi also plays a crucial role in  community governance. It enables users to participate in  voting processes and actively contribute to  decision-making within the QUBY Ai community. This ensures  that the community has a say in the direction and  development of the project. QuByChain Integration; With  the introduction of QuByChain, QuByAi will likely play a  vital role in this powerful blockchain's ecosystem. Its  use in QubyChain's Layer 2 solutions can enhance its  utility and potentially offer additional benefits to token  holders.",
        head4:
          "   A limited quantity of 1000 NFTs will be created within the QuBy Ai project.",
        head5: {
          one: " Open a web browser on your computer. (Not available on Android and IOS devices)",
          two: `   Visit the website&nbsp; <a target="blank" href="https://www.QUBYAIGAME.com/">www.QUBYAIGAME.com</a>`,
          three: `  Look for a registration or sign-up option on the
          website and create an account if you don't already
          have one. You may need to provide some basic
          information to register.`,
          four: "   4.Once you have registered and logged in, you should be able to access the game and start playing for free.",
          five: "   Follow the on-screen instructions or tutorials provided on the website to learn how to play the game  and enjoy the experience.",
        },
      },
      join: {
        btn: "Play Now",
        btn1: "Join Twitter",
        heading: "  Win Prizes monthly Updates on Twitter",
        para1: ` Each month, top players of Quby Ai will be rewarded with
        Prizes.`,
        para2: "Follow us on Twitter for the most recent updates.",
      },
      fotter: {
        para: "  QuBy Ai combines gaming innovation with the allure of a  memecoin, <br /> bolstered by secure blockchain technolog.",
        email: "Email",
        address: "Address",
        Platform: "Platform",
        Company: "Company",
        Resources: "Resources",
        Address: "Address",
        addressdetail: "650 Wellesley Street East, Toronto, Canada",
        copyright: "QuBy Ai & QuBychain All rights Reserved",
        placeholderSuccess: "Thanks! Let's stay connected",
        platform1: {
          heading: "Platform",
          link1: "QuBy Ai Game",
          link2: "QuByChain",
          link3: "QuBy Dapp ",
        },
        Company1: {
          heading: "Company",
          link1: "Contact",
          link2: "Blog",
          link3: "Marketing",
        },
        Resources1: {
          heading: "Resources",
          link1: "Whitepaper",
          link2: "Security",
          link3: "Whitelist",
        },
        privacy: "Privacy Policy",
        Cookies: "Cookies",
      },
      roadmap: {
        heading: "Road Map ",
        heading1: "How It All Started",
        phase: "Phase",
        phase1: {
          line1: "Team Creation",
          line2: "Beta Test Release of QuBy Ai Game",
          line3: "Website Design and Launch : QuBy Ai Token",
          line4: "Launch of Social Media on Twitter and Telegram ",
          line5: "Monthly Meme contest start",
        },
        phase2: {
          line1: "Expansion of Team",
          line2: "Release of WhitePaper V.1",
          line3: "Launch of QuBy Ai Game as Free to Play : Qubaria Level",
          line4: "Achieving 10,000 Followers on Twitter and Telegram",
          line5: "Start of Presale Registration",
          line6: "Initiation of Airdrop Promotion",
          line7: "Website Design and Launch : QuBy Ai Game",
          line8: "Website Design and Launch : QuByChain Blockchain",
        },
        phase3: {
          line1: "Audit of $QYAI Smart Contract",
          line2: "Presale start on QuBy Ai Website and Launchpads",
          line3: "Deployment of Contract with Burn Mechanism",
          line4: "Listings on Centralized Exchanges (DEX)",
          line5: "Listings on CoinGecko and CoinMarketCap          ",
          line6: "$QUBAI token staking start          ",
          line7: "Launch of New Game Levels",
          line8: "Introduction of World Ranking Gamer Rewards          ",
        },
        phase4: {
          line1: "Further Expansion of Team          ",
          line2:
            "Deployment of NFTs and Introduction of New Game Characters          ",
          line3: "Monthly Launch of New Game Levels          ",
          line4: "Inauguration of the QuBy Game International Cup          ",
          line5: "Listings on Major Centralized Exchanges (DEX)          ",
          line6:
            "Collaborations and International Marketing for the Game          ",
          line7:
            "Launch of Web 3.0 Play-to-Earn Version Using $QYAI Token          ",
          line8: "Commencement of QuByChain Blockchain Development          ",
          line9: "QuBySwap website launch          ",
          line10: "Additional Details to Be Disclosed…          ",
        },
      },
    },
  },
  fra: {
    translation: {
      comingsoon: "bientôt disponible",
      nav: {
        btn: "Staking",
        link1: "Jeu QuBy",
        link2: "NFT",
        link3: "QuByChain",
      },
      hero: {
        heading1: "Faire progresser Memecoin :",
        heading2: "Libérez le",
        heading3: " Pouvoir de QuBy Ai",
        btn1: "Jouer maintenant",
        btn2: "Acheter maintenant",
      },
      nft: {
        heading: "Nos NFT",
        subheading: "Collection de 1000 QuBy Ai NFT",
        para: "Explorez les 1000 NFT QuBy Ai exclusifs, conçus de manière unique pour incarner l'essence innovante du projet. Chaque NFT, symbole de créativité et d'évolution, enrichit l'univers QuBy Ai, permettant aux collectionneurs de devenir des parties intégrantes de son parcours transformateur.",
      },
      news: {
        heading1: "Inscrivez-vous à notre newsletter pour rester",
        heading2: "à jour avec nous !",
        placeholder: "Entrez votre e-mail",
        Subscribe: "S'abonner",
      },
      BuyTax: "Taxe d'achat",
      SellTax: "Taxe de vente",
      Community: "Communauté",
      TotalSupply: "Offre totale",
      connect: "Connecter le portefeuille (Bientôt disponible)",
      bsc: "Acheter sur BSC",
      buy: "Comment acheter ?",
      presale: {
        heading1: "Prévente",
        btn: "Liste blanche",
        raised: "Collecté : Prévente non démarrée",
        choose: "Choisissez votre devise",
        pay: "vous Payez",
        recive: "vous recevez",
        heading2: "Jouez, misez et gagnez avec QuBy Ai",
        para1:
          "Découvrez QuBy Ai, la plateforme de jeu de pointe avec une technologie d'IA pour les joueurs passionnés.",
        para2:
          "offrant des paris dynamiques et du staking passif pour des récompenses importantes.",
      },
      intro: {
        ai: "Présentation de QuBy Ai",
        btn: "Liste blanche",
        heading:
          "La Fusion de QuBy, le célèbre caractère chinois, avec Ai Chatgpt",
        para1:
          "Découvrez <b>QuBy Ai</b>, un projet révolutionnaire basé sur la blockchain Solana qui fusionne Memecoin, jeu Ai et NFT pour des jeux innovants et rentables.",
        para2:
          "Nous brûlons des jetons $QYAI pour l'efficacité et assurer la transparence du code. Rejoignez la révolution QuBy Ai !",
      },
      game: {
        btn: "Jouer maintenant",
        heading: "Redéfinir le jeu en ligne à l'ère du Web 3.0.",
        btn1: "Visitez Maintenant",
        heading1: "Du Memecoin à l'écosystème blockchain.",
        para1:
          "QuBy Ai est un jeu en ligne révolutionnaire Web 3.0, gratuit et chargé d'opportunités pour les joueurs de gagner des jetons $QYAI. En exploitant la puissance de la blockchain, le jeu redéfinit l'expérience utilisateur en mélangeant un gameplay engageant avec des résultats gratifiants, marquant une évolution significative dans l'industrie du jeu.",
        para2:
          "QuBy Ai est une force transformatrice dans l'économie des mèmes, mêlant ChatGPT, Gamefi, développement NFT et environnements Web 3.0. Ce n'est pas tout, il établit sa propre blockchain pour des expériences de jeu et la prise de décision communautaire. <br /> Préparez-vous à débloquer des opportunités et à assister à la transformation de QuBy Ai.",
      },
      teko: {
        heading: "Tokenomics",
        subheading: "Un mécanisme de gravure robuste",
        para: "QuBy Ai met en œuvre une <b>gravure de 10%</b> à chaque vente de jetons, favorisant la rareté et améliorant la valeur des jetons au sein de son écosystème.",
      },
      team: {
        heading: "Notre équipe",
        heading1: "Rencontrez l'équipe QuBy Ai",
        para: "L'équipe de QuBy est composée d'artistes accomplis, de développeurs et d'experts en marketing <br /> et blockchain, chacun s'engageant à mener le projet vers ses objectifs ambitieux grâce à des technologies et des stratégies innovantes.",
      },
      faq: {
        heading: "Questions et Réponses",
        faq: "Questions Fréquemment Posées",
      },
      faqhead: {
        head1: "Qu'est-ce que QuBy Ai ?",
        head2: "Qu'est-ce qui rend QuBy Ai plus efficace ?",
        head3: "Pourquoi devrais-je acheter QuByAi ?",
        head4: "Combien de NFT seront créés ?",
        head5: "Comment puis-je jouer à QuBy Ai ?",
      },
      faqbody: {
        head1:
          "QuBy Ai est un projet qui combine un jeton de mème, un jeu vidéo gratuit sur <a target='blank' href='https://www.QUBYAIGAME.com/'>www.QUBYAIGAME.com</a>, et le développement d'une blockchain innovante appelée &nbsp; <a target='blank' href='http://www.qubychain.com/'>www.QuByChain.com</a>, offrant un mélange unique de crypto-monnaie, de divertissement et de technologie blockchain.",
        head2:
          "QuBy Ai est plus efficace grâce à son utilisation de l'algorithme ChatGPT pour le code de jeu et la création de contrats intelligents, ainsi que son mécanisme de gravure de jetons qui réduit l'offre lorsque les jetons sont vendus, améliorant ainsi l'efficacité globale du système.",
        head3:
          "Play-to-Earn ; QuByAi est le jeton principal dans l'écosystème QuBy Ai, permettant aux utilisateurs de participer à des activités play-to-earn dans le jeu QuBy Ai. Cela signifie que vous pouvez gagner des récompenses et des incitations en jouant au jeu, offrant une opportunité de bénéficier de votre expérience de jeu. Implication communautaire ; QuByAi joue également un rôle crucial dans la gouvernance de la communauté. Il permet aux utilisateurs de participer aux processus de vote et de contribuer activement à la prise de décision au sein de la communauté QUBY Ai. Cela garantit que la communauté a son mot à dire dans l'orientation et le développement du projet. Intégration QuByChain ; Avec l'introduction de QuByChain, QuByAi jouera probablement un rôle essentiel dans l'écosystème puissant de cette blockchain. Son utilisation dans les solutions de couche 2 de QubyChain peut améliorer son utilité et offrir potentiellement des avantages supplémentaires aux détenteurs de jetons.",
        head4:
          "Un nombre limité de 1000 NFT seront créés dans le cadre du projet QuBy Ai.",
        head5: {
          one: "Ouvrez un navigateur Web sur votre ordinateur. (Non disponible sur les appareils Android et iOS)",
          two: `Visitez le site Web&nbsp; <a target='blank' href='https://www.QUBYAIGAME.com/'>www.QUBYAIGAME.com</a>`,
          three: `Recherchez une option d'inscription ou d'inscription sur le site Web et créez un compte si vous n'en avez pas déjà un. Vous devrez peut-être fournir quelques informations de base pour vous inscrire.`,
          four: "Une fois que vous vous êtes inscrit et connecté, vous devriez pouvoir accéder au jeu et commencer à jouer gratuitement.",
          five: "Suivez les instructions à l'écran ou les tutoriels fournis sur le site Web pour apprendre à jouer au jeu et profiter de l'expérience.",
        },
      },
      join: {
        btn: "Jouer maintenant",
        btn1: "Rejoindre Twitter",
        heading: "Gagnez Solana avec des mises à jour mensuelles sur Twitter",
        para1:
          "Chaque mois, les meilleurs joueurs de Quby Ai seront récompensés avec de l'Solana.",
        para2: "Suivez-nous sur Twitter pour les dernières mises à jour.",
      },
      fotter: {
        para: "QuBy Ai combine l'innovation du jeu avec l'attrait d'une memecoin, <br /> renforcé par une technologie blockchain sécurisée.",
        email: "Email",
        address: "Adresse",
        Platform: "Plateforme",
        Company: "Société",
        Resources: "Ressources",
        Address: "Adresse",
        addressdetail: "650 Wellesley Street East, Toronto, Canada",
        copyright: "QuBy Ai & QuBychain Tous droits réservés",
        placeholderSuccess: "Merci ! Restons connectés",
        platform1: {
          heading: "Plateforme",
          link1: "QuBy Ai Jeu",
          link2: "QuByChain",
          link3: "QuBy Dapp",
        },
        Company1: {
          heading: "Société",
          link1: "Contact",
          link2: "Blog",
          link3: "Marketing",
        },
        Resources1: {
          heading: "Ressources",
          link1: "Livret blanc",
          link2: "Sécurité",
          link3: "Liste blanche",
        },
        privacy: "Politique de confidentialité",
        Cookies: "Cookies",
      },
      roadmap: {
        heading: "Feuille de route",
        heading1: "Comment tout a commencé",
        phase: "Phase",
        phase1: {
          line1: "Création de l'équipe",
          line2: "Lancement du test bêta du jeu QuBy Ai",
          line3: "Conception et lancement du site Web : Jeton QuBy Ai",
          line4: "Lancement des réseaux sociaux sur Twitter et Telegram",
          line5: "Début du concours de mèmes mensuel",
        },
        phase2: {
          line1: "Expansion de l'équipe",
          line2: "Publication du Livret blanc V.1",
          line3: "Lancement du jeu QuBy Ai en mode gratuit : Niveau Qubaria",
          line4: "Atteinte de 10 000 abonnés sur Twitter et Telegram",
          line5: "Début de l'inscription à la prévente",
          line6: "Lancement de la promotion de largage aérien",
          line7: "Conception et lancement du site Web : Jeu QuBy Ai",
          line8: "Conception et lancement du site Web : Blockchain QuByChain",
        },
        phase3: {
          line1: "Audit du contrat intelligent $QYAI",
          line2:
            "Début de la prévente sur le site Web QuBy Ai et les Launchpads",
          line3: "Déploiement du contrat avec mécanisme de brûlage",
          line4: "Inscriptions sur les bourses centralisées (DEX)",
          line5: "Inscriptions sur CoinGecko et CoinMarketCap",
          line6: "Début du staking du jeton $QUBAI",
          line7: "Lancement de nouveaux niveaux de jeu",
          line8:
            "Introduction des récompenses aux joueurs du classement mondial",
        },
        phase4: {
          line1: "Expansion supplémentaire de l'équipe",
          line2:
            "Déploiement des NFT et introduction de nouveaux personnages de jeu",
          line3: "Lancement mensuel de nouveaux niveaux de jeu",
          line4: "Inauguration de la Coupe internationale de jeu QuBy",
          line5: "Inscriptions sur les principales bourses centralisées (DEX)",
          line6: "Collaborations et marketing international pour le jeu",
          line7:
            "Lancement de la version Web 3.0 Play-to-Earn utilisant le jeton $QYAI",
          line8: "Début du développement de la blockchain QuByChain",
          line9: "Lancement du site Web QuBySwap",
          line10: "Plus de détails à venir…",
        },
      },
    },
  },
  chi: {
    translation: {
      comingsoon: "即将推出",
      nav: {
        btn: "质押",
        link1: "QuBy 游戏",
        link2: "NFT",
        link3: "QuByChain",
      },
      hero: {
        heading1: "推进 Meme 币：",
        heading2: "释放",
        heading3: "QuBy Ai 的力量",
        btn1: "立即玩",
        btn2: "立即购买",
      },
      nft: {
        heading: "我们的 NFT",
        subheading: "1000 QuBy Ai NFT 集合",
        para: "探索独家的 1000 个 QuBy Ai NFT，独特设计以体现项目的创新本质。每个 NFT 都是创意和演变的象征，丰富了 QuBy Ai 宇宙，让收藏家成为其变革性旅程的重要组成部分。",
      },
      news: {
        heading1: "订阅我们的新闻通讯，保持",
        heading2: "与我们保持最新联系！",
        placeholder: "输入您的邮箱",
        Subscribe: "订阅",
      },
      BuyTax: "购买税",
      SellTax: "销售税",
      Community: "社区",
      TotalSupply: "总供应量",
      connect: "连接钱包（即将推出）",
      bsc: "在 BSC 上购买",
      buy: "如何购买？",
      presale: {
        heading1: "预售",
        btn: "白名单",
        raised: "已筹集：未开始预售",
        choose: "选择您的货币",
        pay: "您支付",
        recive: "您收到",
        heading2: "与 QuBy Ai 玩、质押和赚取",
        para1:
          "发现 QuBy Ai，这是一个拥有 AI 技术的前沿游戏平台，面向热情的玩家。",
        para2: "提供丰厚奖励的动态投注和被动质押。",
      },
      intro: {
        ai: "介绍 QuBy Ai",
        btn: "白名单",
        heading: "将 QuBy，中国标志性角色，与 Ai Chatgpt 融合",
        para1:
          "介绍 <b>QuBy Ai</b>，这是一个创新的以太坊区块链项目，融合了 Meme 币、Ai 游戏和 NFT，进行创新和具有成本效益的游戏。",
        para2:
          "我们燃烧 $QYAI 代币以提高效率并确保代码透明。加入 QuBy Ai 革命！",
      },
      game: {
        btn: "立即玩",
        heading: "在 Web 3.0 时代重新定义在线游戏。",
        btn1: "立即访问",
        heading1: "从 Meme 币到区块链生态系统。",
        para1:
          "QuBy Ai 是一款颠覆性的 Web 3.0 在线游戏，免费玩，为玩家赚取 $QYAI 代币提供了丰富的机会。利用区块链的力量，游戏将引人入胜的游戏玩法与有益的结果融为一体，标志着游戏行业的重大演变。",
        para2:
          "QuBy Ai 是 Meme 经济的变革力量，融合了 ChatGPT、Gamefi、NFT 开发和 Web 3.0 环境。它并未止步于此 - 它正在建立自己的区块链，用于玩赚体验和社区决策。 <br /> 准备解锁机会，见证 QuBy Ai 的转变。",
      },
      teko: {
        heading: "Tokenomics（代币经济学）",
        subheading: "一个强大的销毁机制",
        para: "QuBy Ai 在每次代币销售中执行 <b>10% 的销毁</b>，促进稀缺性，并增强其生态系统内的代币价值。",
      },
      team: {
        heading: "我们的团队",
        heading1: "见识 QuBy Ai 团队",
        para: "QuBy 的团队由成熟的艺术家、开发者以及营销和区块链方面的专家组成，每个人都致力于通过创新的技术和策略引导项目朝着雄心勃勃的目标迈进。",
      },
      faq: {
        heading: "问题与解答",
        faq: "常见问题",
      },
      faqhead: {
        head1: "QuBy Ai 是什么？",
        head2: "QuBy Ai 有什么让它更有效的地方？",
        head3: "我为什么应该购买 QuByAi？",
        head4: "将发行多少个 NFT？",
        head5: "我如何玩 QuBy Ai 游戏？",
      },
      faqbody: {
        head1:
          "QuBy Ai 是一个结合了 Meme 币代币、在 <a target='blank' href='https://www.QUBYAIGAME.com/'>www.QUBYAIGAME.com</a> 上的免费视频游戏，以及一个创新的区块链<a target='blank' href='http://www.qubychain.com/'>www.QuByChain.com</a> 的项目，提供了加密货币、娱乐和区块链技术的独特融合。",
        head2:
          "QuBy Ai 更有效的原因在于它使用 ChatGPT 算法进行游戏代码和智能合约的创建，以及其代币销毁机制，在代币销售时减少供应量，从而提高整体系统的效率。",
        head3:
          "玩赚；QuByAi 是 QuBy Ai 生态系统中的主要代币，允许用户参与 QuBy Ai 游戏中的玩赚活动。这意味着您可以通过玩游戏赚取奖励和激励，为您的游戏体验提供了赚钱的机会。社区参与；QuByAi 在社区治理中也起着至关重要的作用。它使用户能够参与投票流程，并积极为 QUBY Ai 社区的决策做出贡献。这确保了社区对项目的方向和发展有话语权。QuByChain 集成；随着 QuByChain 的推出，QuByAi 很可能在这个强大区块链的生态系统中扮演重要角色。其在 QubyChain 的第二层解决方案中的使用可以增强其效用，并可能为代币持有者提供额外的好处。",
        head4: "在 QuBy Ai 项目中将创建有限数量的 1000 个 NFT。",
        head5: {
          one: "在您的计算机上打开一个网页浏览器。（不支持安卓和 iOS 设备）",
          two: `访问网站&nbsp; <a target='blank' href='https://www.QUBYAIGAME.com/'>www.QUBYAIGAME.com</a>`,
          three: `在网站上查找注册或登录选项，如果您还没有帐户，则创建一个帐户。您可能需要提供一些基本信息来注册。`,
          four: "注册并登录后，您应该能够访问游戏并开始免费玩游戏。",
          five: "按照网站上提供的屏幕说明或教程学习如何玩游戏并享受游戏体验。",
        },
      },
      join: {
        btn: "立即玩",
        btn1: "加入 Twitter",
        heading: "在 Twitter 上每月更新赢取以太币",
        para1: "每个月，Quby Ai 的顶级玩家将获得以太币奖励。",
        para2: "在 Twitter 上关注我们获取最新更新。",
      },
      fotter: {
        para: "QuBy Ai结合了游戏创新与Meme加密货币的吸引力，通过安全的区块链技术提升了其价值。",
        email: "电子邮件",
        address: "地址",
        Platform: "平台",
        Company: "公司",
        Resources: "资源",
        Address: "地址",
        addressdetail: "加拿大多伦多威尔斯利街东650号",
        copyright: "QuBy Ai & QuBychain 版权所有",
        placeholderSuccess: "谢谢！让我们保持联系",
        platform1: {
          heading: "平台",
          link1: "QuBy Ai游戏",
          link2: "QuByChain",
          link3: "QuBy Dapp",
        },
        Company1: {
          heading: "公司",
          link1: "联系",
          link2: "博客",
          link3: "营销",
        },
        Resources1: {
          heading: "资源",
          link1: "白皮书",
          link2: "安全性",
          link3: "白名单",
        },
        privacy: "隐私政策",
        Cookies: "Cookies",
      },
      roadmap: {
        heading: "路线图",
        heading1: "起源",
        phase: "阶段",
        phase1: {
          line1: "团队创建",
          line2: "QuBy Ai游戏的Beta测试发布",
          line3: "网站设计和发布：QuBy Ai代币",
          line4: "在Twitter和Telegram上发布社交媒体",
          line5: "每月Meme比赛开始",
        },
        phase2: {
          line1: "团队扩展",
          line2: "白皮书V.1发布",
          line3: "QuBy Ai游戏作为免费游玩发布：Qubaria等级",
          line4: "在Twitter和Telegram上获得1万关注者",
          line5: "开始预售注册",
          line6: "开始空投促销",
          line7: "网站设计和发布：QuBy Ai游戏",
          line8: "网站设计和发布：QuByChain区块链",
        },
        phase3: {
          line1: "$QYAI智能合约审计",
          line2: "QuBy Ai网站和发行平台上的预售开始",
          line3: "具有燃烧机制的合约部署",
          line4: "在中心化交易所（CEX）上市",
          line5: "在CoinGecko和CoinMarketCap上市",
          line6: "$QUBAI代币质押开始",
          line7: "推出新的游戏关卡",
          line8: "引入世界排名游戏奖励",
        },
        phase4: {
          line1: "团队进一步扩展",
          line2: "NFT部署和新游戏角色引入",
          line3: "每月发布新游戏关卡",
          line4: "启动QuBy游戏国际杯",
          line5: "在主要中心化交易所（CEX）上市",
          line6: "为游戏进行合作和国际营销",
          line7: "使用$QYAI代币推出Web 3.0游戏赚取版本",
          line8: "启动QuByChain区块链开发",
          line9: "QuBySwap网站发布",
          line10: "更多细节待披露",
        },
      },
    },
  },
  kor: {
    translation: {
      comingsoon: "곧 출시 예정",
      nav: {
        btn: "스테이킹",
        link1: "QuBy 게임",
        link2: "NFT",
        link3: "QuByChain",
      },
      hero: {
        heading1: "메멘토코인 발전 중:",
        heading2: "QuBy Ai의 ",
        heading3: " 파워 발휘",
        btn1: "지금 플레이",
        btn2: "지금 구매",
      },
      nft: {
        heading: "우리의 NFT",
        subheading: "1000개의 QuBy Ai NFT 컬렉션",
        para: "독점적인 1000개의 QuBy Ai NFT를 탐험해보세요. 이 프로젝트의 혁신적 본질을 담은 독특하게 디자인된 각 NFT는 창의력과 진화의 상징이며, 수집가들이 그 변혁적인 여정의 필수적인 부분이 될 수 있습니다.",
      },
      news: {
        heading1: "최신 소식을 받아보려면",
        heading2: "우리와 함께하세요!",
        placeholder: "이메일을 입력하세요",
        Subscribe: "구독하기",
      },
      BuyTax: "구매 세",
      SellTax: "판매 세",
      Community: "커뮤니티",
      TotalSupply: "총 공급량",
      connect: "지갑 연결 (곧 출시 예정)",
      bsc: "BSC에서 구매",
      buy: "구매 방법?",
      presale: {
        heading1: "프리세일",
        btn: "화이트리스트",
        raised: "모금 완료: 프리세일 미시작",
        choose: "통화 선택",
        pay: "지불 금액",
        recive: "받는 금액",
        heading2: "QuBy Ai로 플레이, 스테이킹 및 수익 창출",
        para1:
          "열정적인 게임 팬을 위한 AI 기술이 탑재된 첨단 게임 플랫폼인 QuBy Ai를 발견하세요.",
        para2: "다이내믹한 베팅과 수동 스테이킹으로 풍부한 보상을 제공합니다.",
      },
      intro: {
        ai: "QuBy Ai 소개",
        btn: "화이트리스트",
        heading: "중국의 상징적 캐릭터 QuBy와 AI Chatgpt의 융합",
        para1: `<b>QuBy Ai</b>를 소개합니다. Meme 코인, AI 게임 및 NFT를 융합하여 혁신적이고 비용 효율적인 게임을 제공하는 이더리움 블록체인 프로젝트입니다.`,
        para2:
          "$QYAI 토큰을 소각하여 효율성을 높이고 코드 투명성을 보장합니다. QuBy Ai 혁명에 참여하세요!",
      },
      game: {
        btn: "지금 플레이",
        heading: "웹 3.0 시대의 온라인 게임 재정의",
        btn1: "지금 방문하기",
        heading1: "Meme 코인에서 블록체인 생태계로",
        para1: `QuBy Ai는 무료로 플레이하고 플레이어들이 $QYAI 토큰을 벌 수 있는 혁신적인 웹 3.0 온라인 게임입니다. 블록체인의 힘을 활용하여 게임은 매력적인 게임플레이와 보상적 결과를 조화롭게 결합하여 게임 산업에서 중요한 진화를 이루고 있습니다.`,
        para2:
          "QuBy Ai는 Meme 경제에서 혁신적인 힘을 지니고 있으며, ChatGPT, Gamefi, NFT 개발 및 Web 3.0 환경을 융합합니다. 그리고 여기서 그치지 않고 자체 블록체인을 구축하여 플레이해서 번 경험과 커뮤니티 결정을 할 수 있는 환경을 제공합니다. <br /> QuBy Ai의 변화를 경험해보세요.",
      },
      teko: {
        heading: "토큰 경제",
        subheading: "강력한 소각 메커니즘",
        para: "QuBy Ai는 모든 토큰 판매마다 <b>10%를 소각</b>하여 토큰 생태계 내에서 토큰 가치를 증대시키고 희소성을 증진시킵니다.",
      },
      team: {
        heading: "우리 팀",
        heading1: "QuBy Ai 팀을 만나보세요",
        para: "QuBy 팀은 뛰어난 아티스트, 개발자 및 마케팅 및 블록체인 전문가로 구성되어 있으며, 혁신적인 기술과 전략을 통해 프로젝트를 야심차게 이끌고 있습니다.",
      },
      faq: {
        heading: "질문과 답변",
        faq: "자주 묻는 질문",
      },
      faqhead: {
        head1: "QuBy Ai는 무엇인가요?",
        head2: "QuBy Ai를 더 효율적으로 만드는 요소는 무엇인가요?",
        head3: "왜 QuByAi를 구매해야 하나요?",
        head4: "얼마나 많은 NFT가 발행될 예정인가요?",
        head5: "QuBy Ai 게임을 어떻게 플레이할 수 있나요?",
      },
      faqbody: {
        head1:
          "QuBy Ai는 Meme 코인 토큰, <a target='blank' href='https://www.QUBYAIGAME.com/'>www.QUBYAIGAME.com</a>에서의 무료 비디오 게임 및 혁신적인 <a target='blank' href='http://www.qubychain.com/'>www.QuByChain.com</a> 블록체인의 개발을 결합한 프로젝트로, 암호화폐, 엔터테인먼트 및 블록체인 기술의 독특한 융합을 제공합니다.",
        head2:
          "QuBy Ai는 게임 코드와 스마트 계약 작성을 위해 ChatGPT 알고리즘을 사용하고 토큰 판매 시 공급량을 감소시키는 토큰 소각 메커니즘을 통해 더 효율적입니다.",
        head3:
          "플레이해서 번; QuByAi는 QuBy Ai 게임 내에서 플레이해서 번 활동에 참여할 수 있는 QuBy Ai 생태계의 기본 토큰으로, 게임을 플레이함으로써 보상과 인센티브를 얻을 수 있어 게임 경험을 통해 혜택을 얻을 수 있습니다. 커뮤니티 참여; QuByAi는 커뮤니티 거버넌스에 중요한 역할을 합니다. 사용자들이 투표 프로세스에 참여하고 QUBY Ai 커뮤니티의 의사 결정에 적극적으로 기여할 수 있도록 합니다. 이를 통해 커뮤니티가 프로젝트의 방향과 발전에 참여하도록 합니다. QuByChain 통합; QuByChain의 도입으로 QuByAi는 이 강력한 블록체인의 생태계에서 중요한 역할을 할 것으로 예상됩니다. QubyChain의 레이어 2 솔루션에서의 사용은 유틸리티를 증대시킬 수 있으며 토큰 보유자에게 추가적인 혜택을 제공할 수 있습니다.",
        head4:
          "QuBy Ai 프로젝트 내에서 한정된 수의 1000개의 NFT가 생성될 예정입니다.",
        head5: {
          one: "컴퓨터에서 웹 브라우저를 엽니다. (Android 및 iOS 기기에서는 사용할 수 없음)",
          two: `웹 사이트 <a target='blank' href='https://www.QUBYAIGAME.com/'>www.QUBYAIGAME.com</a>을 방문합니다.`,
          three: `웹 사이트에서 등록 또는 로그인 옵션을 찾아 이미 계정이 없으면 계정을 생성합니다. 등록하기 위해 기본 정보를 제공해야 할 수도 있습니다.`,
          four: "등록 및 로그인하면 게임에 액세스하고 무료로 게임을 시작할 수 있어야 합니다.",
          five: "웹 사이트에서 제공하는 화면 지침 또는 튜토리얼을 따라 게임을 플레이하고 즐거움을 느껴보세요.",
        },
      },
      join: {
        btn: "지금 플레이",
        btn1: "Twitter 가입하기",
        heading: "매월 Twitter에서 이더리움 얻기",
        para1: "매월, Quby Ai의 최고 플레이어들은 이더리움을 받습니다.",
        para2: "최신 업데이트를 받으려면 Twitter에서 팔로우하세요.",
      },
      fotter: {
        para: "QuBy Ai는 게임 혁신과 Memecoin의 매력을 결합하여 안전한 블록체인 기술로 보강합니다.",
        email: "이메일",
        address: "주소",
        Platform: "플랫폼",
        Company: "회사",
        Resources: "자원",
        Address: "주소",
        addressdetail: "캐나다 토론토 웰리즐리 스트리트 동 650번지",
        copyright: "QuBy Ai 및 QuBychain. 판권 소유",
        placeholderSuccess: "감사합니다! 계속 연락하겠습니다",
        platform1: {
          heading: "플랫폼",
          link1: "QuBy Ai 게임",
          link2: "QuByChain",
          link3: "QuBy Dapp",
        },
        Company1: {
          heading: "회사",
          link1: "연락처",
          link2: "블로그",
          link3: "마케팅",
        },
        Resources1: {
          heading: "자원",
          link1: "백서",
          link2: "보안",
          link3: "화이트리스트",
        },
        privacy: "개인정보 보호정책",
        Cookies: "쿠키",
      },
      roadmap: {
        heading: "로드맵",
        heading1: "모든 시작",
        phase: "단계",
        phase1: {
          line1: "팀 생성",
          line2: "QuBy Ai 게임 베타 테스트 출시",
          line3: "웹사이트 디자인 및 출시: QuBy Ai 토큰",
          line4: "Twitter 및 Telegram 소셜 미디어 출시",
          line5: "매월 Meme 콘테스트 시작",
        },
        phase2: {
          line1: "팀 확장",
          line2: "백서 V.1 출시",
          line3: "무료로 플레이할 수 있는 QuBy Ai 게임 출시: Qubaria 레벨",
          line4: "Twitter 및 Telegram 1만 팔로워 달성",
          line5: "프리세일 등록 시작",
          line6: "에어드랍 프로모션 시작",
          line7: "웹사이트 디자인 및 출시: QuBy Ai 게임",
          line8: "웹사이트 디자인 및 출시: QuByChain 블록체인",
        },
        phase3: {
          line1: "$QYAI 스마트 계약 감사",
          line2: "QuBy Ai 웹사이트 및 런치패드에서 프리세일 시작",
          line3: "소각 메커니즘을 갖춘 계약 배포",
          line4: "중앙화 거래소(DEX) 상장",
          line5: "CoinGecko 및 CoinMarketCap 상장",
          line6: "$QUBAI 토큰 스테이킹 시작",
          line7: "새로운 게임 레벨 출시",
          line8: "월드 랭킹 게이머 보상 소개",
        },
        phase4: {
          line1: "팀 추가 확장",
          line2: "NFT 배포 및 새로운 게임 캐릭터 소개",
          line3: "매월 새로운 게임 레벨 출시",
          line4: "QuBy 게임 국제 컵 개최",
          line5: "주요 중앙화 거래소(DEX) 상장",
          line6: "게임을 위한 협력 및 국제 마케팅",
          line7: "$QYAI 토큰을 사용한 웹 3.0 플레이-투-언 출시",
          line8: "QuByChain 블록체인 개발 시작",
          line9: "QuBySwap 웹사이트 출시",
          line10: "추가 세부사항은 공개 예정",
        },
      },
    },
  },
  nld: {
    translation: {
      comingsoon: "Binnenkort beschikbaar",
      nav: {
        btn: "Staking",
        link1: "QuBy Game",
        link2: "NFT",
        link3: "QuByChain",
      },
      hero: {
        heading1: "Vooruitstrevende Memecoin:",
        heading2: "Ontketen de ",
        heading3: " Kracht van QuBy Ai",
        btn1: "Speel Nu",
        btn2: "Koop Nu",
      },
      nft: {
        heading: "Onze NFT's",
        subheading: "1000 QuBy Ai NFT-collectie",
        para: "Verken de exclusieve 1000 QuBy Ai NFT's, uniek ontworpen om de innovatieve essentie van het project te belichamen. Elke NFT, een symbool van creativiteit en evolutie, verrijkt het QuBy Ai-universum en stelt verzamelaars in staat integrale onderdelen te worden van zijn transformerende reis.",
      },
      news: {
        heading1: "Blijf op de hoogte ",
        heading2: " met onze nieuwsbrief!",
        placeholder: "Voer uw e-mailadres in",
        Subscribe: "Abonneren",
      },
      BuyTax: "Koopbelasting",
      SellTax: "Verkoopbelasting",
      Community: "Gemeenschap",
      TotalSupply: "Totale Voorraad",
      connect: "Portemonnee Verbinden (Binnenkort beschikbaar)",
      bsc: "Koop op BSC",
      buy: "Hoe te kopen?",
      presale: {
        heading1: "Voorverkoop",
        btn: "Whitelist",
        raised: "Opgeworpen: Voorverkoop niet gestart",
        choose: "Kies uw valuta ",
        pay: "U betaalt",
        recive: "U ontvangt",
        heading2: "Speel, Stak & Verdien met QuBy Ai",
        para1:
          "Ontdek QuBy Ai, het geavanceerde gamingplatform met AI-technologie voor gepassioneerde gamers.",
        para2:
          "Het biedt dynamische weddenschappen en passief staken voor rijke beloningen.",
      },
      intro: {
        ai: "Introductie van QuBy Ai",
        btn: "Whitelist",
        heading:
          "De Fusie van QuBy, het Iconische Chinese Personage, met Ai Chatgpt",
        para1: `Maak kennis met <b>QuBy Ai</b>, een baanbrekend Solana blockchainproject dat Memecoin, Ai Game en NFT's combineert voor innovatief en kosteneffectief gamen.`,
        para2:
          "We branden $QYAI-tokens voor efficiëntie en zorgen voor code-transparantie. Doe mee met de QuBy Ai-revolutie!",
      },
      game: {
        btn: "Speel Nu",
        heading: "Herdefiniëring van Online Gaming in het Web 3.0 Tijdperk.",
        btn1: " Bezoek Nu",
        heading1: "Van Memecoin tot Blockchain Ecosysteem.",
        para1: `QuBy Ai is een baanbrekend Web 3.0 online spel, gratis te spelen en geladen met mogelijkheden voor spelers om $QYAI-tokens te verdienen. Door de kracht van blockchain herschrijft het spel de gebruikerservaring door boeiende gameplay te combineren met lonende resultaten, wat een significante evolutie in de gamingindustrie markeert.`,
        para2:
          "QuBy Ai is een transformerende kracht in de meme-economie, waarbij ChatGPT, Gamefi, NFT-ontwikkeling en Web 3.0-omgevingen samenkomen. Het stopt daar niet - het stelt zijn eigen blockchain vast voor play-to-earn-ervaringen en community-besluitvorming. <br /> Maak je klaar om kansen te ontgrendelen en getuige te zijn van de transformatie van QuBy Ai.",
      },
      teko: {
        heading: "Tokenomics",
        subheading: "Een Robuust Brandmechanisme",
        para: "QuBy Ai past een <b>10% brandmechanisme</b> toe bij elke tokenverkoop, ter bevordering van schaarste en versterking van de tokenwaarde binnen zijn ecosysteem.",
      },
      team: {
        heading: "Ons Team",
        heading1: "Maak kennis met het QuBy Ai-team",
        para: "Het team van QuBy bestaat uit ervaren artiesten, ontwikkelaars en experts op het gebied van marketing en blockchain, die elk toegewijd zijn om het project naar zijn ambitieuze doelen te leiden door middel van innovatieve technologieën en strategieën.",
      },
      faq: {
        heading: "Vragen & Antwoorden",
        faq: "Veelgestelde vragen",
      },
      faqhead: {
        head1: "Waar gaat QuBy Ai precies over?",
        head2: "Wat maakt QuBy Ai efficiënter?",
        head3: "Waarom zou ik QuByAi kopen?",
        head4: "Hoeveel NFT's zullen worden gemunt?",
        head5: "Hoe kan ik het QuBy Ai-spel spelen?",
      },
      faqbody: {
        head1: `QuBy Ai is een project dat een meme-coin token, een gratis videogame op <a target="blank" href="https://www.QUBYAIGAME.com/">www.QUBYAIGAME.com</a>, en de ontwikkeling van een innovatieve blockchain genaamd &nbsp; <a target="blank" href="http://www.qubychain.com/">www.QuByChain.com</a> combineert, en biedt een unieke mix van cryptocurrency, entertainment en blockchaintechnologie.`,
        head2:
          "QuBy Ai is efficiënter vanwege het gebruik van het ChatGPT-algoritme voor spelscode en slimme contractcreatie, en vanwege het tokens verbrandingsmechanisme dat het aanbod vermindert wanneer tokens worden verkocht, wat de algehele systeemefficiëntie verbetert.",
        head3:
          "Play-to-Earn; QuByAi is de primaire token in het QuBy Ai-ecosysteem, waardoor gebruikers kunnen deelnemen aan play-to-earn-activiteiten binnen het QuBy Ai-spel. Dit betekent dat je beloningen en prikkels kunt verdienen door het spel te spelen, waardoor je kunt profiteren van je game-ervaring. Community-betrokkenheid; QuByAi speelt ook een cruciale rol in het bestuur van de gemeenschap. Het stelt gebruikers in staat deel te nemen aan stemprocessen en actief bij te dragen aan de besluitvorming binnen de QUBY Ai-gemeenschap. Dit zorgt ervoor dat de gemeenschap inspraak heeft in de richting en ontwikkeling van het project. QuByChain Integratie; Met de introductie van QuByChain zal QuByAi waarschijnlijk een belangrijke rol spelen in dit krachtige blockchain-ecosysteem. Het gebruik ervan in de Layer 2-oplossingen van QubyChain kan het nut ervan vergroten en mogelijk extra voordelen bieden aan tokenhouders.",
        head4:
          "Een beperkte hoeveelheid van 1000 NFT's zal worden gecreëerd binnen het QuBy Ai-project.",
        head5: {
          one: "Open een webbrowser op uw computer. (Niet beschikbaar op Android- en iOS-apparaten)",
          two: `Bezoek de website&nbsp; <a target="blank" href="https://www.QUBYAIGAME.com/">www.QUBYAIGAME.com</a>`,
          three: `Zoek op de website naar een registratie- of aanmeldingsoptie en maak een account aan als u er nog geen heeft. U moet mogelijk enkele basisgegevens verstrekken om u te registreren.`,
          four: "Zodra u zich heeft geregistreerd en bent ingelogd, zou u toegang moeten hebben tot het spel en gratis kunnen beginnen met spelen.",
          five: "Volg de instructies of tutorials op het scherm die op de website worden aangeboden om te leren hoe u het spel kunt spelen en geniet van de ervaring.",
        },
      },
      join: {
        btn: "Speel Nu",
        btn1: "Word Lid op Twitter",
        heading: "Maandelijks Solana winnen op Twitter",
        para1: `Elke maand worden de topspelers van Quby Ai beloond met Solana.`,
        para2: "Volg ons op Twitter voor de meest recente updates.",
      },
      fotter: {
        para: "QuBy Ai combineert gaming innovatie met de aantrekkingskracht van een memecoin, versterkt door veilige blockchain technologie.",
        email: "E-mail",
        address: "Adres",
        Platform: "Platform",
        Company: "Bedrijf",
        Resources: "Bronnen",
        Address: "Adres",
        addressdetail: "650 Wellesley Street East, Toronto, Canada",
        copyright: "QuBy Ai & QuBychain Alle rechten voorbehouden",
        placeholderSuccess: "Bedankt! Laten we verbonden blijven",
        platform1: {
          heading: "Platform",
          link1: "QuBy Ai Game",
          link2: "QuByChain",
          link3: "QuBy Dapp",
        },
        Company1: {
          heading: "Bedrijf",
          link1: "Contact",
          link2: "Blog",
          link3: "Marketing",
        },
        Resources1: {
          heading: "Bronnen",
          link1: "Whitepaper",
          link2: "Beveiliging",
          link3: "Whitelist",
        },
        privacy: "Privacybeleid",
        Cookies: "Cookies",
      },
      roadmap: {
        heading: "Wegkaart",
        heading1: "Hoe het allemaal begon",
        phase: "Fase",
        phase1: {
          line1: "Teamcreatie",
          line2: "Beta Test Release van QuBy Ai Game",
          line3: "Website Ontwerp en Lancering: QuBy Ai Token",
          line4: "Lancering van Social Media op Twitter en Telegram",
          line5: "Start van de maandelijkse Meme-wedstrijd",
        },
        phase2: {
          line1: "Teamuitbreiding",
          line2: "Release van WhitePaper V.1",
          line3:
            "Lancering van QuBy Ai Game als gratis te spelen: Qubaria Level",
          line4: "Bereiken van 10.000 volgers op Twitter en Telegram",
          line5: "Start van Presale Registratie",
          line6: "Initiatie van Airdrop Promotie",
          line7: "Website Ontwerp en Lancering: QuBy Ai Game",
          line8: "Website Ontwerp en Lancering: QuByChain Blockchain",
        },
        phase3: {
          line1: "Audit van $QYAI Smart Contract",
          line2: "Presale start op QuBy Ai Website en Launchpads",
          line3: "Implementatie van Contract met Brandmechanisme",
          line4: "Noteringen op Gecentraliseerde Beurzen (DEX)",
          line5: "Noteringen op CoinGecko en CoinMarketCap",
          line6: "$QUBAI token staking start",
          line7: "Lancering van Nieuwe Game Levels",
          line8: "Introductie van Wereldranglijst Gamer Beloningen",
        },
        phase4: {
          line1: "Verdere Uitbreiding van het Team",
          line2:
            "Implementatie van NFT's en Introductie van Nieuwe Gamepersonages",
          line3: "Maandelijkse Lancering van Nieuwe Game Levels",
          line4: "Inhuldiging van de QuBy Game International Cup",
          line5: "Noteringen op Belangrijke Gecentraliseerde Beurzen (DEX)",
          line6: "Samenwerkingen en Internationale Marketing voor het Spel",
          line7: "Lancering van Web 3.0 Play-to-Earn Versie met $QYAI Token",
          line8: "Start van QuByChain Blockchain Ontwikkeling",
          line9: "QuBySwap website lancering",
          line10: "Extra Details Worden Binnenkort Bekendgemaakt",
        },
      },
    },
  },
  rus: {
    translation: {
      comingsoon: "Скоро",
      nav: {
        btn: "Стейкинг",
        link1: "QuBy Game",
        link2: "NFT",
        link3: "QuByChain",
      },
      hero: {
        heading1: "Развитие меме-коина:",
        heading2: "Раскройте ",
        heading3: " Силу QuBy Ai",
        btn1: "Играть сейчас",
        btn2: "Купить сейчас",
      },
      nft: {
        heading: "Наши NFT",
        subheading: "Коллекция 1000 QuBy Ai NFT",
        para: "Исследуйте эксклюзивные 1000 QuBy Ai NFT, уникально разработанные для воплощения инновационной сущности проекта. Каждый NFT — символ творчества и эволюции, обогащает вселенную QuBy Ai, позволяя коллекционерам стать неотъемлемой частью ее трансформационного путешествия.",
      },
      news: {
        heading1: "Подпишитесь на нашу рассылку",
        heading2: " и оставайтесь в курсе!",
        placeholder: "Введите ваш адрес электронной почты",
        Subscribe: "Подписаться",
      },
      BuyTax: "Налог на покупку",
      SellTax: "Налог на продажу",
      Community: "Сообщество",
      TotalSupply: "Общее количество",
      connect: "Подключить кошелек (скоро)",
      bsc: "Купить на BSC",
      buy: "Как купить?",
      presale: {
        heading1: "Предпродажа",
        btn: "Белый список",
        raised: "Собрано: Предпродажа не началась",
        choose: "Выберите вашу валюту",
        pay: "вы платите",
        recive: "вы получаете",
        heading2: "Играйте, делайте ставки и зарабатывайте с QuBy Ai",
        para1:
          "Откройте для себя QuBy Ai — передовую игровую платформу с искусственным интеллектом для страстных геймеров.",
        para2:
          "Предлагает динамичные ставки и пассивное стейкинг для получения богатых наград.",
      },
      intro: {
        ai: "Представляем QuBy Ai",
        btn: "Белый список",
        heading:
          "Слияние QuBy, иконического китайского персонажа, с Ai Chatgpt",
        para1: `Представляем <b>QuBy Ai</b>, новаторский проект Solana blockchain, объединяющий мем-коин, Ai Game и NFT для инновационной и экономной игры.`,
        para2:
          "Мы сжигаем токены $QYAI для эффективности и обеспечиваем прозрачность кода. Присоединяйтесь к революции QuBy Ai!",
      },
      game: {
        btn: "Играть сейчас",
        heading: "Переопределение онлайн-игр в эпоху Web 3.0.",
        btn1: " Перейти сейчас",
        heading1: "От меме-коина к блокчейн-экосистеме.",
        para1: `QuBy Ai — это новаторская онлайн-игра Web 3.0, бесплатная для игры и загруженная возможностями для игроков заработать токены $QYAI. Пользуясь мощью блокчейна, игра переопределяет пользовательский опыт, совмещая увлекательный геймплей с вознаграждением, что является значительным прорывом в игровой индустрии.`,
        para2:
          "QuBy Ai — это трансформационная сила в мем-экономике, совмещающая ChatGPT, Gamefi, разработку NFT и окружение Web 3.0. Но это не все — она устанавливает собственный блокчейн для игр заработка и принятия решений в сообществе. <br /> Приготовьтесь разблокировать возможности и увидеть трансформацию QuBy Ai.",
      },
      teko: {
        heading: "Токеномика",
        subheading: "Мощный механизм сжигания",
        para: "QuBy Ai применяет <b>10% сжигание</b> на каждую продажу токенов, способствуя дефициту и усилению стоимости токена внутри своей экосистемы.",
      },
      team: {
        heading: "Наша Команда",
        heading1: "Познакомьтесь с командой QuBy Ai",
        para: "Команда QuBy состоит из опытных художников, разработчиков и экспертов по маркетингу и блокчейну, каждый из которых привержен достижению амбициозных целей проекта с помощью инновационных технологий и стратегий.",
      },
      faq: {
        heading: "Вопросы и ответы",
        faq: "Часто задаваемые вопросы",
      },
      faqhead: {
        head1: "В чем суть QuBy Ai?",
        head2: "Что делает QuBy Ai более эффективным?",
        head3: "Почему стоит покупать QuByAi?",
        head4: "Сколько будет создано NFT?",
        head5: "Как играть в QuBy Ai?",
      },
      faqbody: {
        head1: `QuBy Ai — это проект, объединяющий мем-коин-токен, бесплатную видеоигру на <a target="blank" href="https://www.QUBYAIGAME.com/">www.QUBYAIGAME.com</a> и разработку инновационного блокчейна по адресу &nbsp; <a target="blank" href="http://www.qubychain.com/">www.QuByChain.com</a>, предлагающий уникальное сочетание криптовалюты, развлечений и технологии блокчейна.`,
        head2:
          "QuBy Ai более эффективен благодаря использованию алгоритма ChatGPT для создания игрового кода и умных контрактов, а также механизма сжигания токенов, который уменьшает предложение при продаже токенов, повышая общую эффективность системы.",
        head3:
          "Играй-чтобы-заработать; QuByAi является основным токеном в экосистеме QuBy Ai, позволяющим пользователям участвовать в играх-чтобы-заработать внутри игры QuBy Ai. Это означает, что вы можете зарабатывать награды и поощрения, играя в игру, предоставляя возможность извлечь выгоду из вашего игрового опыта. Участие сообщества; QuByAi также играет важную роль в управлении сообществом. Он позволяет пользователям участвовать в процессах голосования и активно вносить свой вклад в принятие решений в сообществе QUBY Ai. Это обеспечивает участие сообщества в определении направления и развития проекта. Интеграция QuByChain; С введением QuByChain QuByAi, скорее всего, сыграет важную роль в этом мощном блокчейн-экосистеме. Его использование в решениях Layer 2 QubyChain может улучшить его полезность и, возможно, предложить дополнительные преимущества для держателей токенов.",
        head4:
          "Будет создано ограниченное количество 1000 NFT в рамках проекта QuBy Ai.",
        head5: {
          one: "Откройте веб-браузер на вашем компьютере. (Недоступно на устройствах Android и iOS)",
          two: `Посетите веб-сайт&nbsp; <a target="blank" href="https://www.QUBYAIGAME.com/">www.QUBYAIGAME.com</a>`,
          three: `Найдите на веб-сайте опцию регистрации или входа и создайте учетную запись, если у вас ее еще нет. Вам может потребоваться предоставить некоторую базовую информацию для регистрации.`,
          four: "После того как вы зарегистрировались и вошли в систему, вы должны иметь доступ к игре и начать играть бесплатно.",
          five: "Следуйте инструкциям или обучающим материалам на экране, предоставленным на веб-сайте, чтобы узнать, как играть в игру и наслаждаться процессом.",
        },
      },
      join: {
        btn: "Играть сейчас",
        btn1: "Присоединиться в Twitter",
        heading: "Ежемесячно выигрывайте Solana на Twitter",
        para1: `Каждый месяц лучшие игроки Quby Ai будут вознаграждены Solana.`,
        para2: "Следите за нами в Twitter для самых свежих обновлений.",
      },
      fotter: {
        para: "QuBy Ai объединяет инновации в игровой индустрии и привлекательность мемкоина, подкрепленные безопасной технологией блокчейн.",
        email: "Электронная почта",
        address: "Адрес",
        Platform: "Платформа",
        Company: "Компания",
        Resources: "Ресурсы",
        Address: "Адрес",
        addressdetail: "650 Wellesley Street East, Торонто, Канада",
        copyright: "QuBy Ai и QuBychain. Все права защищены",
        placeholderSuccess: "Спасибо! Давайте останемся на связи",
        platform1: {
          heading: "Платформа",
          link1: "QuBy Ai Game",
          link2: "QuByChain",
          link3: "QuBy Dapp",
        },
        Company1: {
          heading: "Компания",
          link1: "Контакт",
          link2: "Блог",
          link3: "Маркетинг",
        },
        Resources1: {
          heading: "Ресурсы",
          link1: "Белая бумага",
          link2: "Безопасность",
          link3: "Белый список",
        },
        privacy: "Политика конфиденциальности",
        Cookies: "Cookies",
      },
      roadmap: {
        heading: "План развития",
        heading1: "Как всё начиналось",
        phase: "Фаза",
        phase1: {
          line1: "Создание команды",
          line2: "Бета-тестирование игры QuBy Ai",
          line3: "Дизайн и запуск веб-сайта: Токен QuBy Ai",
          line4: "Запуск социальных сетей на Twitter и Telegram",
          line5: "Начало ежемесячного конкурса мемов",
        },
        phase2: {
          line1: "Расширение команды",
          line2: "Выпуск белой бумаги версии V.1",
          line3: "Запуск игры QuBy Ai как бесплатной игры: Уровень Qubaria",
          line4: "Достижение 10 000 подписчиков в Twitter и Telegram",
          line5: "Начало регистрации на предпродажу",
          line6: "Инициирование Airdrop-промо",
          line7: "Дизайн и запуск веб-сайта: Игра QuBy Ai",
          line8: "Дизайн и запуск веб-сайта: Блокчейн QuByChain",
        },
        phase3: {
          line1: "Аудит умного контракта $QYAI",
          line2: "Старт предпродажи на веб-сайте QuBy Ai и Launchpads",
          line3: "Развертывание контракта с механизмом сжигания",
          line4: "Листинг на Централизованных биржах (DEX)",
          line5: "Листинг на CoinGecko и CoinMarketCap",
          line6: "Старт стейкинга токена $QUBAI",
          line7: "Запуск новых уровней игры",
          line8: "Введение наград для геймеров по мировому рейтингу",
        },
        phase4: {
          line1: "Дальнейшее расширение команды",
          line2: "Развертывание NFT и введение новых персонажей игры",
          line3: "Ежемесячный запуск новых уровней игры",
          line4: "Инаугурация международного кубка QuBy Game",
          line5: "Листинг на основных Централизованных биржах (DEX)",
          line6: "Сотрудничества и международный маркетинг для игры",
          line7: "Запуск версии Web 3.0 Play-to-Earn с токеном $QYAI",
          line8: "Начало разработки блокчейна QuByChain",
          line9: "Запуск веб-сайта QuBySwap",
          line10: "Дополнительные детали будут объявлены в дальнейшем",
        },
      },
    },
  },
  jpn: {
    translation: {
      comingsoon: "間もなく公開",
      nav: {
        btn: "ステーキング",
        link1: "QuBy ゲーム",
        link2: "NFT",
        link3: "QuByChain",
      },
      hero: {
        heading1: "メームコインを前進させる：",
        heading2: "QuBy Aiの",
        heading3: "パワーを解き放て",
        btn1: "今すぐプレイ",
        btn2: "今すぐ購入",
      },
      nft: {
        heading: "当社のNFT",
        subheading: "1000個の QuBy Ai NFT コレクション",
        para: "プロジェクトの革新的な本質を具現化するために独自にデザインされた1000個の QuBy Ai NFT をご覧ください。それぞれのNFTは創造性と進化の象徴であり、コレクターがその変革の旅の不可欠な部分となることを可能にします。",
      },
      news: {
        heading1: "最新情報をお届けするために",
        heading2: "ニュースレターにご参加ください！",
        placeholder: "メールアドレスを入力",
        Subscribe: "登録する",
      },
      BuyTax: "購入税",
      SellTax: "売却税",
      Community: "コミュニティ",
      TotalSupply: "総供給量",
      connect: "ウォレットに接続（間もなく）",
      bsc: "BSCで購入",
      buy: "購入方法",
      presale: {
        heading1: "プレセール",
        btn: "ホワイトリスト",
        raised: "調達済み：プリセールが開始されていません",
        choose: "通貨を選択",
        pay: "支払い額",
        recive: "受け取り額",
        heading2: "QuBy Ai で遊んでステークして稼ぐ",
        para1:
          "情熱的なゲーマー向けのAI技術を搭載した先駆的なゲーミングプラットフォームQuBy Aiを発見してください。",
        para2:
          "豊富な報酬のためのダイナミックなベッティングとパッシブステーキングを提供します。",
      },
      intro: {
        ai: "QuBy Ai をご紹介",
        btn: "ホワイトリスト",
        heading: "QuBy、中国の象徴的な文字とAi Chatgptの融合",
        para1: `<b>QuBy Ai</b> は、革新的でコスト効率の高いゲームのために、メームコイン、Ai Game、NFTを統合したSolanaブロックチェーンプロジェクトです。`,
        para2:
          "$QYAI トークンを効率的に燃やし、コードの透明性を確保しています。QuBy Ai の革命に参加してください！",
      },
      game: {
        btn: "今すぐプレイ",
        heading: "Web 3.0時代のオンラインゲームを再定義します。",
        btn1: "今すぐ訪問",
        heading1: "メームコインからブロックチェーンエコシステムへ。",
        para1: `QuBy Ai は、無料でプレイでき、$QYAI トークンを稼ぐための機会に満ちた革新的なWeb 3.0オンラインゲームです。`,
        para2:
          "QuBy Ai は、ChatGPT、Gamefi、NFT開発、Web 3.0環境を融合した変革的な力です。ここで止まらず、プレイして稼ぐ体験やコミュニティの意思決定のために独自のブロックチェーンを確立しています。<br /> QuBy Ai の変革を目撃する準備をしてください。",
      },
      teko: {
        heading: "トークノミクス",
        subheading: "強力な燃焼メカニズム",
        para: "QuBy Ai は、トークンの販売ごとに<b>10%の燃焼</b>を実施し、生態系内でのトークンの希少性を促進し、価値を高めています。",
      },
      team: {
        heading: "当社のチーム",
        heading1: "QuBy Ai チームに会いましょう",
        para: "QuByのチームは、マーケティングとブロックチェーンの分野での専門家、そして開発者で構成されており、革新的な技術と戦略によってプロジェクトを目標に向かわせることに献身しています。",
      },
      faq: {
        heading: "質問と回答",
        faq: "よくある質問",
      },
      faqhead: {
        head1: "QuBy Ai とは何ですか？",
        head2: "QuBy Ai をより効率的にするものは何ですか？",
        head3: "なぜ QuByAi を購入する必要がありますか？",
        head4: "何個の NFT が作成されますか？",
        head5: "QuBy Ai をどのようにプレイできますか？",
      },
      faqbody: {
        head1: `QuBy Ai は、<a target="blank" href="https://www.QUBYAIGAME.com/">www.QUBYAIGAME.com</a> で無料のビデオゲームと革新的なブロックチェーン <a target="blank" href="http://www.qubychain.com/">www.QuByChain.com</a> の開発を組み合わせたプロジェクトです。これは、暗号通貨、エンターテインメント、およびブロックチェーン技術のユニークな組み合わせを提供します。`,
        head2:
          "QuBy Ai は、ゲームコードとスマートコントラクトの作成に ChatGPT アルゴリズムを使用し、トークンの売却時に供給を減らすトークンの燃焼メカニズムを使用することで、より効率的です。",
        head3:
          "プレイして稼ぐ; QuByAi は、QuBy Ai ゲーム内でプレイして稼ぐための主要なトークンです。これは、ゲームをプレイすることで報酬やインセンティブを得ることができ、ゲーム体験から利益を得る機会を提供します。 コミュニティ参加; QuByAi はコミュニティのガバナンスで重要な役割を果たします。ユーザーが投票プロセスに参加し、QUBY Ai コミュニティ内で積極的に貢献し、プロジェクトの方向性や開発に関与できるようにします。 QuByChain 統合; QuByChain の導入により、QuByAi はこの強力なブロックチェーンエコシステムで重要な役割を果たす可能性があります。 QubyChain のレイヤー2ソリューションでの使用は、ホルダーに追加の利益を提供する可能性があります。",
        head4:
          "QuBy Ai プロジェクト内で1000個のNFTの限られた数量が作成されます。",
        head5: {
          one: "コンピューターのウェブブラウザを開きます（AndroidやiOSデバイスでは利用できません）。",
          two: `ウェブサイト <a target="blank" href="https://www.QUBYAIGAME.com/">www.QUBYAIGAME.com</a> を訪問します`,
          three: `ウェブサイトで登録またはサインアップのオプションを探し、すでにアカウントを持っていない場合はアカウントを作成します。登録のために基本的な情報を提供する必要があるかもしれません。`,
          four: "登録およびログインが完了したら、ゲームにアクセスして無料でプレイできるはずです。",
          five: "画面に表示される指示やウェブサイトで提供されるチュートリアルに従って、ゲームのプレイ方法を学んで体験を楽しんでください。",
        },
      },
      join: {
        btn: "今すぐプレイ",
        btn1: "Twitterで参加",
        heading: "Twitterで毎月Solanaを獲得",
        para1: "毎月、最高のQuBy AiプレイヤーがSolanaで報酬を受け取ります。",
        para2: "最新情報をチェックするためにTwitterでフォローしてください。",
      },
      fotter: {
        para: "QuBy Aiは、セキュアなブロックチェーン技術に支えられたゲームの革新とミームコインの魅力を組み合わせています。",
        email: "メール",
        address: "住所",
        Platform: "プラットフォーム",
        Company: "会社",
        Resources: "リソース",
        Address: "住所",
        addressdetail: "カナダ、トロント、ウェルズリー通り東650番地",
        copyright: "QuBy Ai & QuBychain。全著作権所有",
        placeholderSuccess: "ありがとうございます！ 連絡を取り合いましょう",
        platform1: {
          heading: "プラットフォーム",
          link1: "QuBy Aiゲーム",
          link2: "QuByChain",
          link3: "QuBy Dapp",
        },
        Company1: {
          heading: "会社",
          link1: "お問い合わせ",
          link2: "ブログ",
          link3: "マーケティング",
        },
        Resources1: {
          heading: "リソース",
          link1: "ホワイトペーパー",
          link2: "セキュリティ",
          link3: "ホワイトリスト",
        },
        privacy: "プライバシーポリシー",
        Cookies: "クッキー",
      },
      roadmap: {
        heading: "ロードマップ",
        heading1: "すべてが始まったきっかけ",
        phase: "フェーズ",
        phase1: {
          line1: "チームの作成",
          line2: "QuBy Aiゲームのベータテストリリース",
          line3: "ウェブサイトのデザインと開設：QuBy Aiトークン",
          line4: "TwitterとTelegramでのソーシャルメディアの開始",
          line5: "毎月のミームコンテストの開始",
        },
        phase2: {
          line1: "チームの拡張",
          line2: "ホワイトペーパーV.1の公開",
          line3: "QuBy Aiゲームの無料プレイとしてのリリース：Qubariaレベル",
          line4: "TwitterとTelegramでの10,000人のフォロワー達成",
          line5: "プレセール登録の開始",
          line6: "Airdropプロモーションの開始",
          line7: "ウェブサイトのデザインと開設：QuBy Aiゲーム",
          line8: "ウェブサイトのデザインと開設：QuByChainブロックチェーン",
        },
        phase3: {
          line1: "$QYAIスマートコントラクトの監査",
          line2: "QuBy AiウェブサイトとLaunchpadsでのプレセール開始",
          line3: "バーンメカニズムを備えたコントラクトのデプロイメント",
          line4: "中央集権取引所（CEX）へのリスティング",
          line5: "CoinGeckoとCoinMarketCapへのリスティング",
          line6: "$QUBAIトークンのステーキング開始",
          line7: "新しいゲームレベルの開始",
          line8: "世界ランキングゲーマーリワードの導入",
        },
        phase4: {
          line1: "チームのさらなる拡大",
          line2: "NFTの展開と新しいゲームキャラクターの導入",
          line3: "毎月の新しいゲームレベルの開始",
          line4: "QuBy Game International Cupの開始",
          line5: "主要な中央集権取引所（CEX）へのリスティング",
          line6: "ゲームのためのコラボレーションと国際的なマーケティング",
          line7: "$QYAIトークンを使用したWeb 3.0 Play-to-Earnバージョンの開始",
          line8: "QuByChainブロックチェーンの開発開始",
          line9: "QuBySwapウェブサイトの開設",
          line10: "詳細は後日開示予定",
        },
      },
    },
  },

  prt: {
    translation: {
      comingsoon: "Em breve",
      nav: {
        btn: "Staking",
        link1: "Jogo QuBy",
        link2: "NFT",
        link3: "QuByChain",
      },
      hero: {
        heading1: "Avançando com Memecoin:",
        heading2: "Liberte o ",
        heading3: " Poder do QuBy Ai",
        btn1: "Jogar Agora",
        btn2: "Comprar Agora",
      },
      nft: {
        heading: "Nossos NFTs",
        subheading: "Coleção de 1000 QuBy Ai NFTs",
        para: "Explore os exclusivos 1000 QuBy Ai NFTs, desenhados de forma única para incorporar a essência inovadora do projeto. Cada NFT, um símbolo de criatividade e evolução, enriquece o universo QuBy Ai, permitindo que os colecionadores se tornem partes integrais de sua jornada transformadora.",
      },
      news: {
        heading1: "Junte-se à nossa newsletter para",
        heading2: " manter-se atualizado!",
        placeholder: "Insira seu E-mail",
        Subscribe: "Inscrever-se",
      },
      BuyTax: "Taxa de Compra",
      SellTax: "Taxa de Venda",
      Community: "Comunidade",
      TotalSupply: "Oferta Total",
      connect: "Conectar carteira (Em breve)",
      bsc: "Comprar na BSC",
      buy: "Como Comprar?",
      presale: {
        heading1: "Pré-venda",
        btn: "Lista de Permissões",
        raised: "Arrecadado: Pré-venda não iniciada",
        choose: "Escolha sua Moeda",
        pay: "você paga",
        recive: "você recebe",
        heading2: "Jogue, Stake e Ganhe com QuBy Ai",
        para1:
          "Descubra QuBy Ai, a plataforma de jogos inovadora com tecnologia de IA para jogadores apaixonados.",
        para2:
          "Oferece apostas dinâmicas e staking passivo para recompensas ricas.",
      },
      intro: {
        ai: "Apresentando o QuBy Ai",
        btn: "Lista de Permissões",
        heading:
          "A Fusão do QuBy, o icônico personagem chinês, com o Ai Chatgpt",
        para1: `Apresentando <b>QuBy Ai</b>, um projeto inovador na blockchain Solana que combina Memecoin, Ai Game e NFTs para jogos inovadores e econômicos.`,
        para2:
          "Nós queimamos tokens $QYAI para eficiência e garantimos transparência no código. Junte-se à revolução QuBy Ai!",
      },
      game: {
        btn: "Jogar Agora",
        heading: "Redefinindo os Jogos Online na Era Web 3.0.",
        btn1: "Visitar Agora",
        heading1: "De Memecoin para Ecossistema Blockchain.",
        para1: `QuBy Ai é um jogo online inovador da Web 3.0, gratuito para jogar e carregado com oportunidades para os jogadores ganharem tokens $QYAI.`,
        para2:
          "QuBy Ai é uma força transformadora na economia de memes, combinando ChatGPT, Gamefi, desenvolvimento de NFTs e ambientes da Web 3.0. Não para por aí - está estabelecendo sua própria blockchain para experiências play-to-earn e tomada de decisão comunitária. <br /> Prepare-se para desbloquear oportunidades e testemunhar a transformação do QuBy Ai.",
      },
      teko: {
        heading: "Tokenomics",
        subheading: "Um Mecanismo de Burn Robusto",
        para: "QuBy Ai implementa uma <b>queima de 10%</b> em cada venda de token, promovendo escassez e aumentando o valor do token dentro de seu ecossistema.",
      },
      team: {
        heading: "Nossa Equipe",
        heading1: "Conheça a Equipe QuBy Ai",
        para: "A equipe da QuBy é composta por artistas, desenvolvedores e especialistas em marketing e blockchain, cada um comprometido em direcionar o projeto para seus ambiciosos objetivos por meio de tecnologias e estratégias inovadoras.",
      },
      faq: {
        heading: "Perguntas e Respostas",
        faq: "Perguntas Frequentes",
      },
      faqhead: {
        head1: "Sobre o que é o QuBy Ai?",
        head2: "O que torna o QuBy Ai mais eficiente?",
        head3: "Por que devo comprar QuByAi?",
        head4: "Quantos NFTs serão criados?",
        head5: "Como jogar QuBy Ai?",
      },
      faqbody: {
        head1: `QuBy Ai é um projeto que combina um token de memecoin, um jogo de vídeo gratuito em <a target="blank" href="https://www.QUBYAIGAME.com/">www.QUBYAIGAME.com</a> e o desenvolvimento de uma blockchain inovadora em <a target="blank" href="http://www.qubychain.com/">www.QuByChain.com</a>, oferecendo uma combinação única de criptomoeda, entretenimento e tecnologia blockchain.`,
        head2:
          "QuBy Ai é mais eficiente devido ao uso do algoritmo ChatGPT para o código do jogo e criação de contratos inteligentes, e seu mecanismo de queima de token que reduz o fornecimento quando os tokens são vendidos, aumentando a eficiência geral do sistema.",
        head3:
          "Jogar-para-ganhar; QuByAi é o token principal no ecossistema QuBy Ai, permitindo que os usuários participem de atividades play-to-earn dentro do jogo QuBy Ai. Isso significa que você pode ganhar recompensas e incentivos jogando o jogo, proporcionando uma oportunidade de se beneficiar da sua experiência de jogo. Envolvimento da Comunidade; QuByAi também desempenha um papel crucial na governança da comunidade. Ele permite que os usuários participem de processos de votação e contribuam ativamente para a tomada de decisões dentro da comunidade QUBY Ai. Isso garante que a comunidade tenha voz na direção e desenvolvimento do projeto. Integração QuByChain; Com a introdução do QuByChain, o QuByAi provavelmente desempenhará um papel vital nessa poderosa blockchain. Seu uso nas soluções de camada 2 do QubyChain pode aprimorar sua utilidade e oferecer benefícios adicionais aos detentores de tokens.",
        head4:
          "Será criada uma quantidade limitada de 1000 NFTs dentro do projeto QuBy Ai.",
        head5: {
          one: "Abra um navegador da web em seu computador (não disponível em dispositivos Android e iOS)",
          two: `Visite o site&nbsp; <a target="blank" href="https://www.QUBYAIGAME.com/">www.QUBYAIGAME.com</a>`,
          three: `Procure por uma opção de registro ou inscrição no site e crie uma conta se você ainda não tiver uma. Você pode precisar fornecer algumas informações básicas para se registrar.`,
          four: "Depois de registrado e logado, você deverá ter acesso ao jogo e começar a jogar de graça.",
          five: "Siga as instruções na tela ou os tutoriais fornecidos no site para aprender a jogar o jogo e aproveitar a experiência.",
        },
      },
      join: {
        btn: "Jogar Agora",
        btn1: "Junte-se no Twitter",
        heading: "Ganhe Solana mensalmente no Twitter",
        para1: `Todo mês, os melhores jogadores de Quby Ai receberão Solana como recompensa.`,
        para2: "Siga-nos no Twitter para as atualizações mais recentes.",
      },
      fotter: {
        para: "O QuBy Ai combina inovação em jogos com o atrativo de uma memecoin, reforçado por uma tecnologia segura de blockchain.",
        email: "E-mail",
        address: "Endereço",
        Platform: "Plataforma",
        Company: "Empresa",
        Resources: "Recursos",
        Address: "Endereço",
        addressdetail: "650 Wellesley Street East, Toronto, Canadá",
        copyright: "QuBy Ai e QuBychain. Todos os direitos reservados",
        placeholderSuccess: "Obrigado! Vamos manter contato",
        platform1: {
          heading: "Plataforma",
          link1: "Jogo QuBy Ai",
          link2: "QuByChain",
          link3: "QuBy Dapp",
        },
        Company1: {
          heading: "Empresa",
          link1: "Contato",
          link2: "Blog",
          link3: "Marketing",
        },
        Resources1: {
          heading: "Recursos",
          link1: "Whitepaper",
          link2: "Segurança",
          link3: "Lista Branca",
        },
        privacy: "Política de Privacidade",
        Cookies: "Cookies",
      },
      roadmap: {
        heading: "Roteiro",
        heading1: "Como Tudo Começou",
        phase: "Fase",
        phase1: {
          line1: "Criação da Equipe",
          line2: "Lançamento do Beta Test do Jogo QuBy Ai",
          line3: "Design e Lançamento do Site: Token QuBy Ai",
          line4: "Lançamento de Mídias Sociais no Twitter e Telegram",
          line5: "Início de Concurso Mensal de Memes",
        },
        phase2: {
          line1: "Expansão da Equipe",
          line2: "Lançamento do WhitePaper V.1",
          line3: "Lançamento do Jogo QuBy Ai como Free to Play: Nível Qubaria",
          line4: "Alcançando 10.000 Seguidores no Twitter e Telegram",
          line5: "Início do Registro para Pré-venda",
          line6: "Início da Promoção de Airdrop",
          line7: "Design e Lançamento do Site: Jogo QuBy Ai",
          line8: "Design e Lançamento do Site: Blockchain QuByChain",
        },
        phase3: {
          line1: "Auditoria do Contrato Inteligente $QYAI",
          line2: "Início da Pré-venda no Site do QuBy Ai e Launchpads",
          line3: "Implantação de Contrato com Mecanismo de Queima",
          line4: "Listagens em Bolsas Centralizadas (DEX)",
          line5: "Listagens na CoinGecko e CoinMarketCap",
          line6: "Início de Stake de Tokens $QUBAI",
          line7: "Lançamento de Novos Níveis do Jogo",
          line8:
            "Introdução de Recompensas para Jogadores com Classificação Mundial",
        },
        phase4: {
          line1: "Expansão Adicional da Equipe",
          line2:
            "Implantação de NFTs e Introdução de Novos Personagens de Jogo",
          line3: "Lançamento Mensal de Novos Níveis do Jogo",
          line4: "Inauguração da Copa Internacional do Jogo QuBy",
          line5: "Listagens nas Principais Bolsas Centralizadas (DEX)",
          line6: "Colaborações e Marketing Internacional para o Jogo",
          line7: "Lançamento da Versão Web 3.0 Play-to-Earn usando Token $QYAI",
          line8: "Início do Desenvolvimento do Blockchain QuByChain",
          line9: "Lançamento do Site QuBySwap",
          line10: "Detalhes Adicionais a Serem Divulgados...",
        },
      },
    },
  },
  tur: {
    translation: {
      comingsoon: "Yakında",
      nav: {
        btn: "Staking",
        link1: "QuBy Oyun",
        link2: "NFT",
        link3: "QuByChain",
      },
      hero: {
        heading1: "Memecoin ile İlerliyor:",
        heading2: "QuBy Ai'nin ",
        heading3: " Gücünü Ortaya Çıkarın",
        btn1: "Şimdi Oyna",
        btn2: "Şimdi Satın Al",
      },
      nft: {
        heading: "NFT'lerimiz",
        subheading: "1000 Adet QuBy Ai NFT Koleksiyonu",
        para: "Projenin yenilikçi özünü yansıtmak üzere benzersiz olarak tasarlanmış olan 1000 adet QuBy Ai NFT'yi keşfedin. Her bir NFT, yaratıcılığın ve evrimin bir simgesi olup koleksiyonerlere dönüşüm yolculuğunun ayrılmaz bir parçası olma imkanı sağlar.",
      },
      news: {
        heading1: "Bizi takipte kalın!",
        heading2: "haber bültenimize abone olun!",
        placeholder: "E-postanızı Girin",
        Subscribe: "Abone Ol",
      },
      BuyTax: "Alım Vergisi",
      SellTax: "Satış Vergisi",
      Community: "Topluluk",
      TotalSupply: "Toplam Arz",
      connect: "Cüzdan Bağla (Yakında)",
      bsc: "BSC'de Satın Al",
      buy: "Nasıl Satın Alabilirim?",
      presale: {
        heading1: "Önsatış",
        btn: "Beyaz Liste",
        raised: "Toplanan: Ön satış başlatılmadı",
        choose: "Para Biriminizi Seçin",
        pay: "Ödemeniz",
        recive: "Aldığınız Miktar",
        heading2: "QuBy Ai ile Oyna, Staking Yap ve Kazan",
        para1:
          "Tutkulu oyuncular için yapay zeka teknolojisi ile geliştirilmiş olan QuBy Ai platformunu keşfedin.",
        para2: "Zengin ödüller için dinamik bahisler ve pasif staking sunuyor.",
      },
      intro: {
        ai: "QuBy Ai Tanıtımı",
        btn: "Beyaz Liste",
        heading: "QuBy, Simgeleşmiş Çin Karakterinin Ai Chatgpt ile Birleşimi",
        para1: `Tüm yenilikçi ve ekonomik bir oyun için Memecoin, Ai Oyun ve NFT'leri bir araya getiren <b>QuBy Ai</b> projesini tanıtıyoruz.`,
        para2:
          "Verimlilik için $QYAI token'ları yakıyoruz ve kodun şeffaflığını sağlıyoruz. QuBy Ai devrimine katılın!",
      },
      game: {
        btn: "Şimdi Oyna",
        heading: "Web 3.0 Çağında Çevrimiçi Oyunları Yeniden Tanımlama.",
        btn1: "Şimdi Ziyaret Et",
        heading1: "Memecoin'den Blockchain Ekosistemine.",
        para1: `QuBy Ai, Web 3.0 için yenilikçi bir çevrimiçi oyun olup oyuncuların $QYAI token'ları kazanma fırsatları sunar.`,
        para2:
          "ChatGPT, Gamefi, NFT geliştirme ve Web 3.0 ortamlarını birleştiren QuBy Ai, kendi blockchain'ini oluşturarak oyun oynayarak kazanma deneyimleri ve topluluk karar alma süreçleri oluşturuyor. <br /> QuBy Ai'nin dönüşümüne hazır olun.",
      },
      teko: {
        heading: "Token Ekonomisi",
        subheading: "Sağlam Bir Yakma Mekanizması",
        para: "QuBy Ai, her token satışında <b> %10 yakma</b> uygulayarak ekosistemi içinde arzın azalmasını ve token değerinin artmasını sağlar.",
      },
      team: {
        heading: "Ekibimiz",
        heading1: "QuBy Ai Ekibi ile Tanışın",
        para: "QuBy ekibi, projeyi yenilikçi teknolojiler ve stratejiler ile hedeflerine doğru yönlendirmeye kararlı olan başarılı sanatçılardan, geliştiricilerden ve pazarlama ve blockchain alanındaki uzmanlardan oluşuyor.",
      },
      faq: {
        heading: "Sıkça Sorulan Sorular",
        faq: "SSS",
      },
      faqhead: {
        head1: "QuBy Ai hakkında nasıl bilgi alabilirim?",
        head2: "QuBy Ai'yi daha verimli kılan nedir?",
        head3: "Neden QuByAi satın almalıyım?",
        head4: "Kaç adet NFT oluşturulacak?",
        head5: "QuBy Ai oyununu nasıl oynayabilirim?",
      },
      faqbody: {
        head1: `QuBy Ai, meme coin token, <a target="blank" href="https://www.QUBYAIGAME.com/">www.QUBYAIGAME.com</a> adresinde ücretsiz bir video oyunu ve <a target="blank" href="http://www.qubychain.com/">www.QuByChain.com</a> adresinde inovatif bir blockchain geliştirme projesidir; bu sayede kripto para birimi, eğlence ve blockchain teknolojisinin benzersiz bir kombinasyonunu sunmaktadır.`,
        head2:
          "QuBy Ai, oyun kodu ve akıllı sözleşme oluşturma için ChatGPT algoritmasını kullanması ve token'ların satışı sırasında arzın azaltılmasına yol açan token yakma mekanizması nedeniyle daha verimlidir.",
        head3:
          "Oyna-kazan; QuByAi, QuBy Ai ekosistemindeki ana token olup kullanıcılara QuBy Ai oyunu içinde oyun-para kazan etkinliklerine katılma imkanı sağlar. Bu, oyun oynayarak ödüller ve teşvikler kazanabileceğiniz anlamına gelir, bu da oyun deneyiminizden faydalanma fırsatı sunar. Topluluk Katılımı; QuByAi aynı zamanda topluluk yönetiminde önemli bir rol oynar. Kullanıcıların oy verme süreçlerine katılmalarını ve QUBY Ai topluluğu içinde karar alıp etkin bir şekilde katkıda bulunmalarını sağlar. Bu, topluluğun projenin yönelim ve gelişiminde söz sahibi olmasını sağlar. QuByChain Entegrasyonu; QuByChain'in tanıtımıyla birlikte, QuByAi'nin bu güçlü blockchain içinde önemli bir rol oynayacağı muhtemeldir. QubyChain'in Katman 2 çözümlerinde kullanımı, token sahiplerine ek faydalar sunabilir ve kullanışlılığını artırabilir.",
        head4: "QuBy Ai projesi içinde sınırlı sayıda 1000 NFT oluşturulacak.",
        head5: {
          one: "Bilgisayarınızdaki bir web tarayıcısını açın (Android ve iOS cihazlarda kullanılamaz)",
          two: `Websitesini ziyaret edin&nbsp; <a target="blank" href="https://www.QUBYAIGAME.com/">www.QUBYAIGAME.com</a>`,
          three: `Websitesinde kayıt veya üyelik seçeneği arayın ve henüz bir hesabınız yoksa bir tane oluşturun. Kaydolmak için temel bilgiler sağlamanız gerekebilir.`,
          four: "Kaydolduktan ve giriş yaptıktan sonra, oyunu erişebilmeli ve ücretsiz olarak oynamaya başlayabilmelisiniz.",
          five: "Ekrandaki talimatları veya web sitesinde verilen eğitim materyallerini takip ederek oyunu nasıl oynayacağınızı öğrenin ve deneyimin tadını çıkarın.",
        },
      },
      join: {
        btn: "Şimdi Oyna",
        btn1: "Twitter'da Katıl",
        heading: "Her ay Twitter'da Solana Kazan",
        para1: `Her ay, en iyi Quby Ai oyuncuları Solana ödülü alacak.`,
        para2: "En son güncellemeler için bizi Twitter'da takip edin.",
      },
      fotter: {
        para: "QuBy Ai, güvenli blok zinciri teknolojisi tarafından desteklenen oyun inovasyonunu mizahî bir kripto para birimi olan memecoin'in çekiciliğiyle birleştiriyor.",
        email: "E-posta",
        address: "Adres",
        Platform: "Platform",
        Company: "Şirket",
        Resources: "Kaynaklar",
        Address: "Adres",
        addressdetail: "650 Wellesley Street East, Toronto, Kanada",
        copyright: "QuBy Ai & QuBychain. Tüm hakları saklıdır",
        placeholderSuccess: "Teşekkürler! İletişimde kalalım",
        platform1: {
          heading: "Platform",
          link1: "QuBy Ai Oyunu",
          link2: "QuByChain",
          link3: "QuBy Dapp",
        },
        Company1: {
          heading: "Şirket",
          link1: "İletişim",
          link2: "Blog",
          link3: "Pazarlama",
        },
        Resources1: {
          heading: "Kaynaklar",
          link1: "Beyaz Kağıt",
          link2: "Güvenlik",
          link3: "Beyaz Liste",
        },
        privacy: "Gizlilik Politikası",
        Cookies: "Çerezler",
      },
      roadmap: {
        heading: "Yol Haritası",
        heading1: "Her Şeyin Başlangıcı",
        phase: "Aşama",
        phase1: {
          line1: "Ekip Oluşturma",
          line2: "QuBy Ai Oyununun Beta Sürümü Yayınlandı",
          line3: "Web Sitesi Tasarımı ve Başlatılması: QuBy Ai Token",
          line4: "Twitter ve Telegram'da Sosyal Medyanın Başlatılması",
          line5: "Aylık Meme Yarışmasının Başlaması",
        },
        phase2: {
          line1: "Ekip Genişletme",
          line2: "WhitePaper V.1'in Yayınlanması",
          line3:
            "QuBy Ai Oyununun Qubaria Seviyesi Olarak Ücretsiz Oynanabilir Olarak Başlatılması",
          line4: "Twitter ve Telegram'da 10.000 Takipçiye Ulaşılması",
          line5: "Presale Kaydının Başlatılması",
          line6: "Airdrop Promosyonunun Başlatılması",
          line7: "Web Sitesi Tasarımı ve Başlatılması: QuBy Ai Oyunu",
          line8: "Web Sitesi Tasarımı ve Başlatılması: QuByChain Blok Zinciri",
        },
        phase3: {
          line1: "$QYAI Akıllı Sözleşmenin Denetimi",
          line2: "QuBy Ai Web Sitesi ve Launchpadlerde Presale Başlatılması",
          line3: "Yakma Mekanizmalı Sözleşmenin Yayınlanması",
          line4: "Merkezi Borsalarda (DEX) Listelenmesi",
          line5: "CoinGecko ve CoinMarketCap'te Listelenmesi",
          line6: "$QUBAI jetonunun staking'inin başlaması",
          line7: "Yeni Oyun Seviyelerinin Başlatılması",
          line8: "Dünya Sıralaması Oyuncu Ödüllerinin Tanıtılması",
        },
        phase4: {
          line1: "Ekip İlerletmesi",
          line2:
            "NFT'lerin Yayınlanması ve Yeni Oyun Karakterlerinin Tanıtılması",
          line3: "Aylık Yeni Oyun Seviyelerinin Yayınlanması",
          line4: "QuBy Game International Cup'ın Açılışı",
          line5: "Büyük Merkezi Borsalarda (DEX) Listelenmesi",
          line6: "Oyun için İşbirlikleri ve Uluslararası Pazarlama",
          line7:
            "$QYAI Jetonu Kullanılan Web 3.0 Play-to-Earn Sürümünün Başlatılması",
          line8: "QuByChain Blok Zincirinin Gelişiminin Başlatılması",
          line9: "QuBySwap Web Sitesinin Açılışı",
          line10: "Ayrıntılar Daha Sonra Açıklanacak",
        },
      },
    },
  },

  deu: {
    translation: {
      comingsoon: "Demnächst",
      nav: {
        btn: "Staking",
        link1: "QuBy Spiel",
        link2: "NFT",
        link3: "QuByChain",
      },
      hero: {
        heading1: "Vorantreiben des Memecoins:",
        heading2: "Entfesseln Sie die ",
        heading3: " Kraft von QuBy Ai",
        btn1: "Jetzt spielen",
        btn2: "Jetzt kaufen",
      },
      nft: {
        heading: "Unsere NFTs",
        subheading: "1000 QuBy Ai NFT Sammlung",
        para: "Entdecken Sie die exklusiven 1000 QuBy Ai NFTs, die einzigartig gestaltet wurden, um die innovative Essenz des Projekts zu verkörpern. Jedes NFT, ein Symbol für Kreativität und Evolution, bereichert das QuBy Ai Universum und ermöglicht es Sammlern, integraler Bestandteil seiner transformatorischen Reise zu werden.",
      },
      news: {
        heading1: "Abonnieren Sie unseren Newsletter, um",
        heading2: " auf dem Laufenden zu bleiben!",
        placeholder: "Geben Sie Ihre E-Mail-Adresse ein",
        Subscribe: "Abonnieren",
      },
      BuyTax: "Kaufsteuer",
      SellTax: "Verkaufssteuer",
      Community: "Gemeinschaft",
      TotalSupply: "Gesamtangebot",
      connect: "Wallet verbinden (Bald verfügbar)",
      bsc: "Auf BSC kaufen",
      buy: "Wie kaufen?",
      presale: {
        heading1: "Vorverkauf",
        btn: "Whitelist",
        raised: "Eingeworben: Vorverkauf nicht gestartet",
        choose: "Wählen Sie Ihre Währung",
        pay: "Sie zahlen",
        recive: "Sie erhalten",
        heading2: "Spielen, Staken und Verdienen mit QuBy Ai",
        para1:
          "Entdecken Sie QuBy Ai, die innovative Spieleplattform mit KI-Technologie für leidenschaftliche Spieler.",
        para2:
          "Bietet dynamische Einsätze und passives Staking für reiche Belohnungen.",
      },
      intro: {
        ai: "Vorstellung von QuBy Ai",
        btn: "Whitelist",
        heading:
          "Die Fusion von QuBy, der ikonischen chinesischen Figur, mit Ai Chatgpt",
        para1: `Stellen Sie sich <b>QuBy Ai</b> vor, ein bahnbrechendes Solana-Blockchain-Projekt, das Memecoin, Ai-Spiele und NFTs für innovatives und kostengünstiges Spielen vereint.`,
        para2:
          "Wir verbrennen $QYAI-Token für Effizienz und stellen die Code-Transparenz sicher. Schließen Sie sich der QuBy Ai-Revolution an!",
      },
      game: {
        btn: "Jetzt spielen",
        heading: "Neugestaltung des Online-Gamings im Web 3.0-Zeitalter.",
        btn1: "Jetzt besuchen",
        heading1: "Vom Memecoin zum Blockchain-Ökosystem.",
        para1: `QuBy Ai ist ein innovatives Web 3.0-Online-Spiel, das kostenlos gespielt werden kann und Spielern die Möglichkeit bietet, $QYAI-Token zu verdienen.`,
        para2:
          "QuBy Ai ist eine transformative Kraft in der Meme-Wirtschaft, die ChatGPT, Gamefi, die Entwicklung von NFTs und Umgebungen des Web 3.0 miteinander verbindet. Doch damit nicht genug - es etabliert seine eigene Blockchain für Play-to-Earn-Erfahrungen und Community-Entscheidungen. <br /> Bereiten Sie sich darauf vor, Möglichkeiten freizuschalten und die Transformation von QuBy Ai zu erleben.",
      },
      teko: {
        heading: "Tokenökonomie",
        subheading: "Ein Robuster Verbrennungsmechanismus",
        para: "QuBy Ai implementiert eine <b>10%ige Verbrennung</b> bei jedem Token-Verkauf, um die Knappheit zu fördern und den Token-Wert innerhalb seines Ökosystems zu steigern.",
      },
      team: {
        heading: "Unser Team",
        heading1: "Lernen Sie das QuBy Ai-Team kennen",
        para: "Das QuBy-Team besteht aus erfahrenen Künstlern, Entwicklern und Experten für Marketing und Blockchain, die sich alle dafür einsetzen, das Projekt durch innovative Technologien und Strategien voranzutreiben.",
      },
      faq: {
        heading: "Fragen & Antworten",
        faq: "Häufig gestellte Fragen",
      },
      faqhead: {
        head1: "Worum geht es bei QuBy Ai?",
        head2: "Was macht QuBy Ai effizienter?",
        head3: "Warum sollte ich QuByAi kaufen?",
        head4: "Wie viele NFTs werden erstellt?",
        head5: "Wie kann ich QuBy Ai spielen?",
      },
      faqbody: {
        head1: `QuBy Ai ist ein Projekt, das einen Meme-Coin-Token, ein kostenloses Videospiel auf <a target="blank" href="https://www.QUBYAIGAME.com/">www.QUBYAIGAME.com</a> und die Entwicklung einer innovativen Blockchain unter <a target="blank" href="http://www.qubychain.com/">www.QuByChain.com</a> kombiniert und damit eine einzigartige Kombination aus Kryptowährung, Unterhaltung und Blockchain-Technologie bietet.`,
        head2:
          "QuBy Ai ist aufgrund der Verwendung des ChatGPT-Algorithmus für die Spieleskript- und Smart-Vertragsentwicklung sowie des Token-Brennmechanismus, der das Angebot bei Token-Verkäufen reduziert und die Gesamteffizienz des Systems erhöht, effizienter.",
        head3:
          "Spielen um zu verdienen; QuByAi ist der Haupttoken im QuBy Ai-Ökosystem und ermöglicht es den Benutzern, an Spielen um zu verdienen-Aktivitäten innerhalb des QuBy Ai-Spiels teilzunehmen. Das bedeutet, dass Sie Belohnungen und Anreize verdienen können, indem Sie das Spiel spielen, was eine Möglichkeit bietet, von Ihrer Spielerfahrung zu profitieren. Community-Beteiligung; QuByAi spielt auch eine wichtige Rolle bei der Community-Governance. Es ermöglicht den Benutzern, an Abstimmungsprozessen teilzunehmen und aktiv zur Entscheidungsfindung in der QUBY Ai-Community beizutragen. Dadurch wird sichergestellt, dass die Community Einfluss auf die Ausrichtung und Entwicklung des Projekts hat. QuByChain-Integration; Mit der Einführung von QuByChain wird QuByAi voraussichtlich eine wichtige Rolle in diesem leistungsstarken Blockchain-Ökosystem spielen. Sein Einsatz in den Layer-2-Lösungen von QubyChain kann seine Nützlichkeit verbessern und möglicherweise zusätzliche Vorteile für Token-Inhaber bieten.",
        head4:
          "Es wird eine begrenzte Anzahl von 1000 NFTs im Rahmen des QuBy Ai-Projekts erstellt.",
        head5: {
          one: "Öffnen Sie einen Webbrowser auf Ihrem Computer (Nicht verfügbar auf Android- und iOS-Geräten)",
          two: `Besuchen Sie die Website&nbsp; <a target="blank" href="https://www.QUBYAIGAME.com/">www.QUBYAIGAME.com</a>`,
          three: `Suchen Sie auf der Website nach einer Registrierungs- oder Anmeldeoption und erstellen Sie ein Konto, wenn Sie noch keines haben. Möglicherweise müssen Sie einige grundlegende Informationen zur Registrierung angeben.`,
          four: "Sobald Sie sich registriert und angemeldet haben, sollten Sie Zugang zum Spiel haben und kostenlos spielen können.",
          five: "Folgen Sie den Bildschirmanweisungen oder den auf der Website bereitgestellten Anleitungen, um zu erfahren, wie Sie das Spiel spielen können, und genießen Sie die Erfahrung.",
        },
      },
      join: {
        btn: "Jetzt spielen",
        btn1: "Treten Sie Twitter bei",
        heading: "Gewinnen Sie jeden Monat Solana auf Twitter",
        para1: `Jeden Monat werden die besten Quby Ai-Spieler mit Solana belohnt.`,
        para2: "Folgen Sie uns auf Twitter für die neuesten Updates.",
      },
      fotter: {
        para: "QuBy Ai vereint Spieleinnovation mit dem Reiz einer Memecoin, gestärkt durch sichere Blockchain-Technologie.",
        email: "E-Mail",
        address: "Adresse",
        Platform: "Plattform",
        Company: "Unternehmen",
        Resources: "Ressourcen",
        Address: "Adresse",
        addressdetail: "650 Wellesley Street East, Toronto, Kanada",
        copyright: "QuBy Ai & QuBychain Alle Rechte vorbehalten",
        placeholderSuccess: "Danke! Bleiben wir in Verbindung",
        platform1: {
          heading: "Plattform",
          link1: "QuBy Ai Spiel",
          link2: "QuByChain",
          link3: "QuBy Dapp",
        },
        Company1: {
          heading: "Unternehmen",
          link1: "Kontakt",
          link2: "Blog",
          link3: "Marketing",
        },
        Resources1: {
          heading: "Ressourcen",
          link1: "Weißbuch",
          link2: "Sicherheit",
          link3: "Whitelist",
        },
        privacy: "Datenschutzbestimmungen",
        Cookies: "Cookies",
      },
      roadmap: {
        heading: "Fahrplan",
        heading1: "Wie Alles Begann",
        phase: "Phase",
        phase1: {
          line1: "Teamerstellung",
          line2: "Beta-Testveröffentlichung des QuBy Ai Spiels",
          line3: "Webseitengestaltung und Veröffentlichung: QuBy Ai Token",
          line4: "Start der Social-Media-Aktivitäten auf Twitter und Telegram",
          line5: "Start des monatlichen Meme-Wettbewerbs",
        },
        phase2: {
          line1: "Teamerweiterung",
          line2: "Veröffentlichung des Whitepapers V.1",
          line3: "Start des QuBy Ai Spiels als Free-to-Play: Qubaria Level",
          line4: "Erreichen von 10.000 Followern auf Twitter und Telegram",
          line5: "Beginn der Vorverkaufsregistrierung",
          line6: "Start der Airdrop-Promotion",
          line7: "Webseitengestaltung und Veröffentlichung: QuBy Ai Spiel",
          line8:
            "Webseitengestaltung und Veröffentlichung: QuByChain Blockchain",
        },
        phase3: {
          line1: "Prüfung des $QYAI Smart Contracts",
          line2: "Vorverkaufsstart auf der QuBy Ai Website und Launchpads",
          line3: "Implementierung des Vertrags mit Verbrennungsmechanismus",
          line4: "Listings an zentralisierten Börsen (DEX)",
          line5: "Listings auf CoinGecko und CoinMarketCap",
          line6: "$QUBAI Token Staking Start",
          line7: "Start neuer Spiellevel",
          line8: "Einführung von weltweiten Ranglisten-Gamer-Belohnungen",
        },
        phase4: {
          line1: "Weitere Teamerweiterung",
          line2:
            "Implementierung von NFTs und Einführung neuer Spielcharaktere",
          line3: "Monatliche Veröffentlichung neuer Spiellevel",
          line4: "Eröffnung des internationalen QuBy Game Cups",
          line5: "Listings an wichtigen zentralisierten Börsen (DEX)",
          line6: "Zusammenarbeit und internationales Marketing für das Spiel",
          line7: "Start der Web 3.0 Play-to-Earn-Version mit dem $QYAI Token",
          line8: "Beginn der Entwicklung der QuByChain Blockchain",
          line9: "Start der QuBySwap-Webseite",
          line10: "Zusätzliche Details werden bekannt gegeben...",
        },
      },
    },
  },

  ita: {
    translation: {
      comingsoon: "Prossimamente",
      nav: {
        btn: "Staking",
        link1: "QuBy Gioco",
        link2: "NFT",
        link3: "QuByChain",
      },
      hero: {
        heading1: "Avanzamento del Memecoin:",
        heading2: "Svela la ",
        heading3: " Potenza di QuBy Ai",
        btn1: "Gioca ora",
        btn2: "Acquista ora",
      },
      nft: {
        heading: "I nostri NFT",
        subheading: "Collezione di 1000 QuBy Ai NFT",
        para: "Esplora gli esclusivi 1000 QuBy Ai NFT, progettati in modo unico per incarnare l'essenza innovativa del progetto. Ogni NFT, simbolo di creatività ed evoluzione, arricchisce l'universo di QuBy Ai, consentendo ai collezionisti di diventare parte integrante del suo viaggio trasformativo.",
      },
      news: {
        heading1: "Iscriviti alla nostra newsletter",
        heading2: " per rimanere aggiornato con noi!",
        placeholder: "Inserisci la tua email",
        Subscribe: "Iscriviti",
      },
      BuyTax: "Tassa di acquisto",
      SellTax: "Tassa di vendita",
      Community: "Comunità",
      TotalSupply: "Fornitura totale",
      connect: "Connetti il portafoglio (Prossimamente)",
      bsc: "Acquista su BSC",
      buy: "Come acquistare?",
      presale: {
        heading1: "Pre-vendita",
        btn: "Whitelist",
        raised: "Raccolto: Pre-vendita non avviata",
        choose: "Scegli la tua valuta",
        pay: "paghi",
        recive: "ricevi",
        heading2: "Gioca, Fai Stake e Guadagna con QuBy Ai",
        para1:
          "Scopri QuBy Ai, la piattaforma di gioco innovativa con tecnologia AI per giocatori appassionati.",
        para2:
          "Offre scommesse dinamiche e staking passivo per ricche ricompense.",
      },
      intro: {
        ai: "Presentazione di QuBy Ai",
        btn: "Whitelist",
        heading:
          "La Fusione di QuBy, l'iconico personaggio cinese, con Ai Chatgpt",
        para1: `Presentiamo <b>QuBy Ai</b>, un innovativo progetto basato su Solana blockchain che unisce Memecoin, Ai Game e NFT per un gioco innovativo ed economico.`,
        para2:
          "Bruciamo i token $QYAI per efficienza e garantiamo la trasparenza del codice. Unisciti alla rivoluzione di QuBy Ai!",
      },
      game: {
        btn: "Gioca ora",
        heading: "Ridefinizione dei Giochi Online nell'Era del Web 3.0.",
        btn1: "Visita ora",
        heading1: "Dal Memecoin all'Ecosistema Blockchain.",
        para1: `QuBy Ai è un innovativo gioco online Web 3.0, gratuito e carico di opportunità per i giocatori di guadagnare token $QYAI. Sfruttando il potere del blockchain, il gioco ridefinisce l'esperienza dell'utente unendo un gameplay coinvolgente a risultati gratificanti, segnando una significativa evoluzione nell'industria dei giochi.`,
        para2:
          "QuBy Ai è una forza trasformativa nell'economia dei meme, unendo ChatGPT, Gamefi, lo sviluppo di NFT e gli ambienti Web 3.0. Ma non finisce qui: sta stabilendo il proprio blockchain per esperienze play-to-earn e decisioni della comunità. <br /> Preparati a sbloccare opportunità e assistere alla trasformazione di QuBy Ai.",
      },
      teko: {
        heading: "Tokenomia",
        subheading: "Un robusto meccanismo di bruciatura",
        para: "QuBy Ai implementa una <b>bruciatura del 10%</b> su ogni vendita di token, promuovendo la scarsità e migliorando il valore del token all'interno del suo ecosistema.",
      },
      team: {
        heading: "Il nostro Team",
        heading1: "Incontra il Team di QuBy Ai",
        para: "Il team di QuBy è composto da artisti esperti, sviluppatori ed esperti di marketing e blockchain, tutti impegnati nel guidare il progetto verso i suoi ambiziosi obiettivi attraverso tecnologie e strategie innovative.",
      },
      faq: {
        heading: "Domande e Risposte",
        faq: "Domande Frequenti",
      },
      faqhead: {
        head1: "Di cosa si tratta QuBy Ai?",
        head2: "Cosa rende QuBy Ai più efficiente?",
        head3: "Perché dovrei acquistare QuByAi?",
        head4: "Quanti NFT verranno creati?",
        head5: "Come posso giocare a QuBy Ai?",
      },
      faqbody: {
        head1: `QuBy Ai è un progetto che combina un token Meme-Coin, un videogioco gratuito su <a target="blank" href="https://www.QUBYAIGAME.com/">www.QUBYAIGAME.com</a>, e lo sviluppo di una blockchain innovativa chiamata &nbsp; <a target="blank" href="http://www.qubychain.com/">www.QuByChain.com</a>, offrendo una combinazione unica di criptovaluta, intrattenimento e tecnologia blockchain.`,
        head2:
          "QuBy Ai è più efficiente grazie all'uso dell'algoritmo ChatGPT per il codice di gioco e la creazione di smart contract, e al meccanismo di bruciatura dei token che riduce l'offerta quando vengono venduti i token, migliorando l'efficienza complessiva del sistema.",
        head3:
          "Gioca per guadagnare; QuByAi è il token principale nell'ecosistema QuBy Ai, che consente agli utenti di partecipare a attività di gioco per guadagnare all'interno del gioco QuBy Ai. Ciò significa che è possibile guadagnare ricompense e incentivi giocando, offrendo l'opportunità di beneficiare della propria esperienza di gioco. Coinvolgimento della comunità; QuByAi gioca anche un ruolo cruciale nella governance della comunità. Consente agli utenti di partecipare ai processi di voto e contribuire attivamente alla presa di decisioni nella comunità di QUBY Ai. Ciò assicura che la comunità abbia voce in capitolo nella direzione e nello sviluppo del progetto. Integrazione di QuByChain; Con l'introduzione di QuByChain, QuByAi probabilmente giocherà un ruolo importante in questo potente ecosistema blockchain. Il suo utilizzo nelle soluzioni di Layer 2 di QubyChain può migliorarne l'utilità e potenzialmente offrire ulteriori vantaggi ai detentori di token.",
        head4:
          "Verrà creato un numero limitato di 1000 NFT all'interno del progetto QuBy Ai.",
        head5: {
          one: "Apri un browser web sul tuo computer (non disponibile su dispositivi Android e iOS)",
          two: `Visita il sito web&nbsp; <a target="blank" href="https://www.QUBYAIGAME.com/">www.QUBYAIGAME.com</a>`,
          three: `Cerca sul sito web un'opzione di registrazione o accesso e crea un account se non ne hai già uno. Potrebbe essere necessario fornire alcune informazioni di base per registrarti.`,
          four: "Una volta registrato e effettuato l'accesso, dovresti avere accesso al gioco e poter giocare gratuitamente.",
          five: "Segui le istruzioni sullo schermo o i tutorial forniti sul sito web per imparare come giocare e goderti l'esperienza.",
        },
      },
      join: {
        btn: "Gioca ora",
        btn1: "Unisciti su Twitter",
        heading: "Vinci Solana ogni mese su Twitter",
        para1: `Ogni mese i migliori giocatori di Quby Ai saranno premiati con Solana.`,
        para2: "Seguici su Twitter per gli ultimi aggiornamenti.",
      },
      fotter: {
        para: "QuBy Ai unisce l'innovazione nel gioco con il fascino di una Memecoin, rafforzata dalla tecnologia blockchain sicura.",
        email: "Email",
        address: "Indirizzo",
        Platform: "Piattaforma",
        Company: "Azienda",
        Resources: "Risorse",
        Address: "Indirizzo",
        addressdetail: "650 Wellesley Street East, Toronto, Canada",
        copyright: "QuBy Ai & QuBychain Tutti i diritti riservati",
        placeholderSuccess: "Grazie! Restiamo in contatto",
        platform1: {
          heading: "Piattaforma",
          link1: "Gioco QuBy Ai",
          link2: "QuByChain",
          link3: "QuBy Dapp",
        },
        Company1: {
          heading: "Azienda",
          link1: "Contatto",
          link2: "Blog",
          link3: "Marketing",
        },
        Resources1: {
          heading: "Risorse",
          link1: "Whitepaper",
          link2: "Sicurezza",
          link3: "Whitelist",
        },
        privacy: "Informativa sulla privacy",
        Cookies: "Cookies",
      },
      roadmap: {
        heading: "Percorso",
        heading1: "Come Tutto è Iniziato",
        phase: "Fase",
        phase1: {
          line1: "Creazione del Team",
          line2: "Rilascio Beta Test di QuBy Ai Game",
          line3: "Progettazione del Sito Web e Lancio: QuBy Ai Token",
          line4: "Avvio sui Social Media su Twitter e Telegram",
          line5: "Inizio del concorso mensile sui meme",
        },
        phase2: {
          line1: "Espansione del Team",
          line2: "Rilascio del WhitePaper V.1",
          line3:
            "Lancio del Gioco QuBy Ai in modalità Free-to-Play: Livello Qubaria",
          line4: "Raggiungimento di 10.000 Follower su Twitter e Telegram",
          line5: "Inizio della Registrazione al Pre-vendita",
          line6: "Avvio della Promozione Airdrop",
          line7: "Progettazione del Sito Web e Lancio: Gioco QuBy Ai",
          line8: "Progettazione del Sito Web e Lancio: QuByChain Blockchain",
        },
        phase3: {
          line1: "Verifica del Contratto Intelligente $QYAI",
          line2: "Inizio della Pre-vendita sul Sito Web di QuBy Ai e Launchpad",
          line3: "Implementazione del Contratto con Meccanismo di Bruciatura",
          line4: "Liste sulle Borse Centralizzate (DEX)",
          line5: "Liste su CoinGecko e CoinMarketCap",
          line6: "Avvio del Token Staking $QUBAI",
          line7: "Lancio di Nuovi Livelli di Gioco",
          line8: "Introduzione dei Premi Mondiali per i Gamer in Classifica",
        },
        phase4: {
          line1: "Ulteriore Espansione del Team",
          line2:
            "Implementazione degli NFT e Introduzione di Nuovi Personaggi di Gioco",
          line3: "Lancio Mensile di Nuovi Livelli di Gioco",
          line4: "Inaugurazione della Coppa Internazionale di Gioco QuBy",
          line5: "Liste sulle Principali Borse Centralizzate (DEX)",
          line6: "Collaborazioni e Marketing Internazionale per il Gioco",
          line7:
            "Lancio della Versione Web 3.0 Play-to-Earn utilizzando il Token $QYAI",
          line8: "Inizio dello Sviluppo della Blockchain QuByChain",
          line9: "Lancio del Sito Web QuBySwap",
          line10: "Ulteriori Dettagli Saranno Rivelati...",
        },
      },
    },
  },

  idn: {
    translation: {
      comingsoon: "Segera Hadir",
      nav: {
        btn: "Staking",
        link1: "Permainan QuBy",
        link2: "NFT",
        link3: "QuByChain",
      },
      hero: {
        heading1: "Mengembangkan Memecoin:",
        heading2: "Lepaskan ",
        heading3: " Keberlanjutan QuBy Ai",
        btn1: "Main Sekarang",
        btn2: "Beli Sekarang",
      },
      nft: {
        heading: "NFT Kami",
        subheading: "Koleksi 1000 QuBy Ai NFT",
        para: "Jelajahi eksklusif 1000 QuBy Ai NFT, dirancang secara unik untuk menggambarkan esensi inovatif proyek. Setiap NFT, simbol kreativitas dan evolusi, memperkaya alam semesta QuBy Ai, memungkinkan kolektor menjadi bagian integral dari perjalanan transformasinya.",
      },
      news: {
        heading1: "Bergabunglah dengan newsletter kami untuk tetap ",
        heading2: " terkini dengan kami!",
        placeholder: "Masukkan Email Anda",
        Subscribe: "Langganan",
      },
      BuyTax: "Pajak Beli",
      SellTax: "Pajak Jual",
      Community: "Komunitas",
      TotalSupply: "Total Persediaan",
      connect: "Hubungkan dompet (Segera Hadir)",
      bsc: "Beli Di BSC",
      buy: "Bagaimana Cara Membeli?",
      presale: {
        heading1: "Pra-penjualan",
        btn: "Whitelist",
        raised: "Terhimpun: Pra-penjualan belum dimulai",
        choose: "Pilih Mata Uang Anda",
        pay: "Anda Bayar",
        recive: "Anda Terima",
        heading2: "Mainkan, Stake & Hasilkan dengan QuBy Ai",
        para1:
          "Temukan QuBy Ai, platform permainan terkini dengan teknologi AI untuk para penggemar",
        para2:
          "pemain, menawarkan taruhan dinamis dan staking pasif untuk hadiah yang besar.",
      },
      intro: {
        ai: "Memperkenalkan QuBy Ai",
        btn: "Whitelist",
        heading: "Fusi QuBy, Karakter Cina Ikonik, Dengan Ai Chatgpt",
        para1:
          "Memperkenalkan <b>QuBy Ai</b>, proyek groundbreaking Solana blockchain yang menggabungkan Memecoin, Ai Game, dan NFT untuk permainan yang inovatif dan hemat biaya.",
        para2:
          "Kami membakar token $QYAI untuk efisiensi dan memastikan transparansi kode. Bergabunglah dengan revolusi QuBy Ai!",
      },
      game: {
        btn: "Main Sekarang",
        heading: "Medefinisikan Kembali Permainan Online di Era Web 3.0.",
        btn1: " Kunjungi Sekarang",
        heading1: "Dari Memecoin ke Ekosistem Blockchain.",
        para1:
          "QuBy Ai adalah game online Web 3.0 yang inovatif, gratis untuk dimainkan dan sarat dengan peluang bagi pemain untuk mendapatkan token $QYAI. Memanfaatkan kekuatan blockchain, game ini mendefinisikan kembali pengalaman pengguna dengan menggabungkan gameplay yang menarik dengan hasil yang memuaskan, menandai evolusi signifikan dalam industri gaming.",
        para2:
          "QuBy Ai adalah kekuatan transformatif dalam ekonomi meme, menggabungkan ChatGPT, Gamefi, pengembangan NFT, dan lingkungan Web 3.0. Tidak berhenti di situ—proyek ini sedang mendirikan blockchainnya sendiri untuk pengalaman bermain dan pengambilan keputusan komunitas. <br /> Bersiaplah untuk membuka peluang dan menyaksikan transformasi QuBy Ai.",
      },
      teko: {
        heading: "Tokenomika",
        subheading: "Mekanisme Pembakaran Kuat",
        para: "QuBy Ai menerapkan pembakaran <b>10%</b> pada setiap penjualan token, mendorong kelangkaan dan meningkatkan nilai token dalam ekosistemnya.",
      },
      team: {
        heading: "Tim Kami",
        heading1: "Bertemu dengan Tim QuBy Ai",
        para: "Tim QuBy terdiri dari seniman, pengembang, dan ahli pemasaran dan blockchain yang berpengalaman, masing-masing berkomitmen untuk memandu proyek menuju tujuan ambisiusnya melalui teknologi dan strategi inovatif.",
      },
      faq: {
        heading: "Pertanyaan & Jawaban",
        faq: "Pertanyaan yang Sering Diajukan",
      },
      faqhead: {
        head1: "Apa itu QuBy Ai?",
        head2: "Apa yang membuat QuBy Ai lebih efisien?",
        head3: "Mengapa saya harus membeli QuByAi?",
        head4: "Berapa banyak NFT yang akan dikeluarkan?",
        head5: "Bagaimana cara memainkan game QuBy Ai?",
      },
      faqbody: {
        head1:
          'QuBy Ai adalah proyek yang menggabungkan token memecoin, game video gratis di <a target="blank" href="https://www.QUBYAIGAME.com/">www.QUBYAIGAME.com</a>, dan pengembangan blockchain inovatif di &nbsp; <a target="blank" href="http://www.qubychain.com/">www.QuByChain.com</a>, menawarkan kombinasi unik dari cryptocurrency, hiburan, dan teknologi blockchain.',
        head2:
          "QuBy Ai lebih efisien karena menggunakan algoritma ChatGPT untuk kode game dan penciptaan kontrak pintar serta mekanisme pembakaran token yang mengurangi pasokan saat token dijual, meningkatkan efisiensi keseluruhan sistem.",
        head3:
          "Bermain untuk Mendapatkan; QuByAi adalah token utama dalam ekosistem QuBy Ai, memungkinkan pengguna untuk berpartisipasi dalam kegiatan bermain untuk mendapatkan di dalam Game QuBy Ai. Ini berarti Anda dapat mendapatkan hadiah dan insentif dengan bermain game, memberikan kesempatan untuk mendapatkan keuntungan dari pengalaman bermain Anda. Keterlibatan Komunitas; QuByAi juga memainkan peran penting dalam tata kelola komunitas. Ini memungkinkan pengguna untuk berpartisipasi dalam proses pemungutan suara dan berkontribusi secara aktif dalam pengambilan keputusan dalam komunitas QUBY Ai. Ini memastikan bahwa komunitas memiliki suara dalam arah dan pengembangan proyek. Integrasi QuByChain; Dengan diperkenalkannya QuByChain, QuByAi kemungkinan besar akan memainkan peran penting dalam ekosistem blockchain yang kuat ini. Penggunaannya dalam solusi Lapisan 2 QubyChain dapat meningkatkan utilitasnya dan mungkin menawarkan manfaat tambahan kepada pemegang token.",
        head4: "Sejumlah terbatas 1000 NFT akan dibuat dalam proyek QuBy Ai.",
        head5: {
          one: "Buka peramban web di komputer Anda. (Tidak tersedia di perangkat Android dan iOS)",
          two: `Kunjungi situs web&nbsp; <a target=\"blank\" href=\"https://www.QUBYAIGAME.com/\">www.QUBYAIGAME.com</a>`,
          three:
            "Cari opsi registrasi atau sign-up di situs web dan buat akun jika Anda belum memiliki satu. Anda mungkin perlu memberikan beberapa informasi dasar untuk mendaftar.",
          four: "Setelah Anda mendaftar dan masuk, Anda seharusnya dapat mengakses game dan mulai bermain secara gratis.",
          five: "Ikuti instruksi di layar atau tutorial yang disediakan di situs web untuk belajar cara memainkan game dan menikmati pengalaman tersebut.",
        },
      },
      join: {
        btn: "Main Sekarang",
        btn1: "Bergabung dengan Twitter",
        heading: "Menangkan Solana dengan pembaruan bulanan di Twitter",
        para1:
          "Setiap bulan, pemain terbaik QuBy Ai akan mendapatkan hadiah Solana.",
        para2: "Ikuti kami di Twitter untuk pembaruan terbaru.",
      },
      fotter: {
        para: "QuBy Ai menggabungkan inovasi gaming dengan daya tarik Memecoin, diperkuat oleh teknologi blockchain yang aman.",
        email: "Email",
        address: "Alamat",
        Platform: "Platform",
        Company: "Perusahaan",
        Resources: "Sumber Daya",
        Address: "Alamat",
        addressdetail: "650 Wellesley Street East, Toronto, Kanada",
        copyright: "QuBy Ai & QuBychain Hak Cipta Dilindungi",
        placeholderSuccess: "Terima kasih! Mari tetap terhubung",
        platform1: {
          heading: "Platform",
          link1: "Game QuBy Ai",
          link2: "QuByChain",
          link3: "QuBy Dapp",
        },
        Company1: {
          heading: "Perusahaan",
          link1: "Kontak",
          link2: "Blog",
          link3: "Pemasaran",
        },
        Resources1: {
          heading: "Sumber Daya",
          link1: "Whitepaper",
          link2: "Keamanan",
          link3: "Daftar Putih",
        },
        privacy: "Kebijakan Privasi",
        Cookies: "Kuki",
      },
      roadmap: {
        heading: "Rencana",
        heading1: "Bagaimana Semuanya Dimulai",
        phase: "Fase",
        phase1: {
          line1: "Pembentukan Tim",
          line2: "Rilis Uji Beta Game QuBy Ai",
          line3: "Desain Situs Web dan Peluncuran: Token QuBy Ai",
          line4: "Peluncuran Media Sosial di Twitter dan Telegram",
          line5: "Mulai Kontes Meme Bulanan",
        },
        phase2: {
          line1: "Ekspansi Tim",
          line2: "Rilis WhitePaper V.1",
          line3:
            "Peluncuran Game QuBy Ai sebagai Gratis untuk Dimainkan: Level Qubaria",
          line4: "Mencapai 10.000 Pengikut di Twitter dan Telegram",
          line5: "Mulai Registrasi Prajual",
          line6: "Inisiasi Promosi Airdrop",
          line7: "Desain dan Peluncuran Situs Web: Game QuBy Ai",
          line8: "Desain dan Peluncuran Situs Web: QuByChain Blockchain",
        },
        phase3: {
          line1: "Audit Kontrak Pintar $QYAI",
          line2: "Prajual dimulai di Situs Web QuBy Ai dan Platform Peluncuran",
          line3: "Implementasi Kontrak dengan Mekanisme Pembakaran",
          line4: "Daftar di Bursa Tertentralisasi (DEX)",
          line5: "Daftar di CoinGecko dan CoinMarketCap",
          line6: "Mulai Staking Token $QUBAI",
          line7: "Peluncuran Level Game Baru",
          line8: "Pengenalan Hadiah Pemain Peringkat Dunia",
        },
        phase4: {
          line1: "Ekspansi Lebih Lanjut Tim",
          line2: "Implementasi NFT dan Pengenalan Karakter Game Baru",
          line3: "Peluncuran Bulanan Level Game Baru",
          line4: "Peresmian Piala Internasional Game QuBy",
          line5: "Daftar di Bursa Tertentralisasi Utama (DEX)",
          line6: "Kolaborasi dan Pemasaran Internasional untuk Game",
          line7:
            "Peluncuran Versi Web 3.0 Main Untuk Hasil Menggunakan Token $QYAI",
          line8: "Awal Pengembangan Blockchain QuByChain",
          line9: "Peluncuran Situs Web QuBySwap",
          line10: "Rincian Tambahan Akan Diungkapkan...",
        },
      },
    },
  },
  vnm: {
    translation: {
      comingsoon: "Sắp ra mắt",
      nav: {
        btn: "Staking",
        link1: "QuBy Game",
        link2: "NFT",
        link3: "QuByChain",
      },
      hero: {
        heading1: "Tiến xa hơn với Memecoin:",
        heading2: "Giải phóng sức mạnh của ",
        heading3: " QuBy Ai",
        btn1: "Chơi Ngay",
        btn2: "Mua Ngay",
      },
      nft: {
        heading: "Các NFT của chúng tôi",
        subheading: "Bộ sưu tập 1000 QuBy Ai NFT",
        para: "Khám phá 1000 QuBy Ai NFT độc quyền, được thiết kế độc đáo để thể hiện bản chất đổi mới của dự án. Mỗi NFT, một biểu tượng của sự sáng tạo và tiến hóa, làm phong phú thêm vũ trụ của QuBy Ai, cho phép người sưu tập trở thành một phần không thể thiếu của hành trình biến đổi của nó.",
      },
      news: {
        heading1: "Tham gia bản tin của chúng tôi để cập nhật ",
        heading2: "tin tức mới nhất với chúng tôi!",
        placeholder: "Nhập Email của bạn",
        Subscribe: "Đăng ký",
      },
      BuyTax: "Thuế Mua",
      SellTax: "Thuế Bán",
      Community: "Cộng đồng",
      TotalSupply: "Tổng Cung",
      connect: "Kết nối ví (Sắp ra mắt)",
      bsc: "Mua Trên BSC",
      buy: "Làm thế nào để Mua?",
      presale: {
        heading1: "Bán trước",
        btn: "Danh sách trắng",
        raised: "Đã gây quỹ: Chưa bắt đầu bán trước",
        choose: "Chọn Loại Tiền của Bạn",
        pay: "bạn trả",
        recive: "bạn nhận",
        heading2: "Chơi, Stake & Kiếm với QuBy Ai",
        para1:
          "Khám phá QuBy Ai, nền tảng chơi game tiên tiến với công nghệ AI cho những người hâm mộ",
        para2:
          "người chơi, cung cấp cược động và staking passives để nhận phần thưởng hậu hĩnh.",
      },
      intro: {
        ai: "Giới thiệu QuBy Ai",
        btn: "Danh sách trắng",
        heading:
          "Sự kết hợp giữa QuBy, Nhân vật Trung Quốc đầy biểu tượng, Với Ai Chatgpt",
        para1:
          "Giới thiệu <b>QuBy Ai</b>, dự án blockchain Solana đột phá kết hợp Memecoin, Ai Game và NFT để tạo ra trò chơi sáng tạo và tiết kiệm chi phí.",
        para2:
          "Chúng tôi đốt cháy token $QYAI để hiệu quả và đảm bảo sự minh bạch của mã code. Hãy tham gia cách mạng QuBy Ai!",
      },
      game: {
        btn: "Chơi Ngay",
        heading: "Định nghĩa lại Trò chơi Trực tuyến trong Kỷ nguyên Web 3.0.",
        btn1: " Ghé Thăm Ngay",
        heading1: "Từ Memecoin đến Hệ sinh thái Blockchain.",
        para1:
          "QuBy Ai là một trò chơi trực tuyến Web 3.0 đột phá, miễn phí để chơi và đầy cơ hội cho người chơi kiếm được token $QYAI. Sử dụng sức mạnh của blockchain, trò chơi định nghĩa lại trải nghiệm người dùng bằng cách kết hợp gameplay hấp dẫn với kết quả đáng khen, đánh dấu sự tiến hóa đáng kể trong ngành công nghiệp game.",
        para2:
          "QuBy Ai là một lực lượng biến đổi trong nền kinh tế meme, kết hợp ChatGPT, Gamefi, phát triển NFT và môi trường Web 3.0. Nó không dừng lại ở đó—nó đang thiết lập blockchain riêng của mình để trải nghiệm chơi để kiếm và quyết định cộng đồng. <br /> Hãy chuẩn bị mở khóa cơ hội và chứng kiến sự biến đổi của QuBy Ai.",
      },
      teko: {
        heading: "Tokenomics",
        subheading: "Một Cơ Chế Đốt Mạnh Mẽ",
        para: "QuBy Ai thực hiện việc <b>đốt cháy 10%</b> trên mỗi giao dịch token, thúc đẩy sự khan hiếm và tăng giá trị token trong hệ sinh thái của nó.",
      },
      team: {
        heading: "Đội của Chúng Tôi",
        heading1: "Gặp gỡ Đội QuBy Ai",
        para: "Đội QuBy bao gồm các nghệ sĩ, nhà phát triển và chuyên gia về tiếp thị và blockchain, mỗi người cam kết đưa dự án đến với các mục tiêu tham vọng của nó thông qua công nghệ và chiến lược đổi mới.",
      },
      faq: {
        heading: "Câu Hỏi & Trả Lời",
        faq: "Câu Hỏi Thường Gặp",
      },
      faqhead: {
        head1: "QuBy Ai là gì?",
        head2: "Làm thế nào làm cho QuBy Ai trở nên hiệu quả hơn?",
        head3: "Tại sao tôi nên mua QuByAi?",
        head4: "Có bao nhiêu NFT sẽ được tạo ra?",
        head5: "Làm thế nào để chơi trò chơi QuBy Ai?",
      },
      faqbody: {
        head1:
          'QuBy Ai là một dự án kết hợp token memecoin, trò chơi video miễn phí trên <a target="blank" href="https://www.QUBYAIGAME.com/">www.QUBYAIGAME.com</a>, và phát triển blockchain đổi mới tại &nbsp; <a target="blank" href="http://www.qubychain.com/">www.QuByChain.com</a>, cung cấp sự kết hợp độc đáo giữa tiền điện tử, giải trí và công nghệ blockchain.',
        head2:
          "QuBy Ai hiệu quả hơn nhờ việc sử dụng thuật toán ChatGPT cho mã game và tạo hợp đồng thông minh cũng như cơ chế đốt token giảm nguồn cung khi token được bán, nâng cao hiệu quả tổng thể của hệ thống.",
        head3:
          "Chơi để Kiếm; QuByAi là token chính trong hệ sinh thái QuBy Ai, cho phép người dùng tham gia các hoạt động chơi để kiếm trong Trò chơi QuBy Ai. Điều này có nghĩa là bạn có thể kiếm được phần thưởng và khuyến mãi bằng cách chơi game, tạo cơ hội để tận dụng từ trải nghiệm chơi game của bạn. Tham Gia Cộng Đồng; QuByAi cũng đóng vai trò quan trọng trong quản trị cộng đồng. Nó cho phép người dùng tham gia vào các quy trình bỏ phiếu và đóng góp tích cực vào việc ra quyết định trong cộng đồng QUBY Ai. Điều này đảm bảo cộng đồng có quyền nói trong hướng đi và phát triển của dự án. Tích Hợp QuByChain; Với việc giới thiệu QuByChain, QuByAi có thể sẽ đóng một vai trò quan trọng trong hệ sinh thái blockchain mạnh mẽ này. Sử dụng của nó trong các giải pháp Lớp 2 của QubyChain có thể cải thiện tính hữu ích của nó và có thể cung cấp các lợi ích bổ sung cho người giữ token.",
        head4:
          "Một lượng giới hạn 1000 NFT sẽ được tạo ra trong dự án QuBy Ai.",
        head5: {
          one: "Mở trình duyệt web trên máy tính của bạn. (Không khả dụng trên thiết bị Android và iOS)",
          two: `Truy cập trang web&nbsp; <a target="blank" href="https://www.QUBYAIGAME.com/">www.QUBYAIGAME.com</a>`,
          three:
            "Tìm tùy chọn đăng ký hoặc đăng nhập trên trang web và tạo tài khoản nếu bạn chưa có. Bạn có thể cần cung cấp một số thông tin cơ bản để đăng ký.",
          four: "Sau khi bạn đã đăng ký và đăng nhập, bạn nên có thể truy cập trò chơi và bắt đầu chơi miễn phí.",
          five: "Tuân theo các hướng dẫn trên màn hình hoặc hướng dẫn được cung cấp trên trang web để tìm hiểu cách chơi trò chơi và tận hưởng trải nghiệm.",
        },
      },
      join: {
        btn: "Chơi Ngay",
        btn1: "Tham Gia Twitter",
        heading: "Giành Solana với cập nhật hàng tháng trên Twitter",
        para1:
          "Mỗi tháng, những người chơi hàng đầu của QuBy Ai sẽ được thưởng Solana.",
        para2: "Theo dõi chúng tôi trên Twitter để cập nhật mới nhất.",
      },
      fotter: {
        para: "QuBy Ai kết hợp sự đổi mới trong game với sức hút của Memecoin, được củng cố bởi công nghệ blockchain an toàn.",
        email: "Email",
        address: "Địa chỉ",
        Platform: "Nền tảng",
        Company: "Công ty",
        Resources: "Tài nguyên",
        Address: "Địa chỉ",
        addressdetail: "650 Wellesley Street East, Toronto, Canada",
        copyright: "QuBy Ai & QuBychain Bản quyền đã được bảo hộ",
        placeholderSuccess: "Cảm ơn! Hãy tiếp tục kết nối",
        platform1: {
          heading: "Nền tảng",
          link1: "Trò chơi QuBy Ai",
          link2: "QuByChain",
          link3: "QuBy Dapp",
        },
        Company1: {
          heading: "Công ty",
          link1: "Liên hệ",
          link2: "Blog",
          link3: "Marketing",
        },
        Resources1: {
          heading: "Tài nguyên",
          link1: "Bài báo trắng",
          link2: "Bảo mật",
          link3: "Danh sách trắng",
        },
        privacy: "Chính sách bảo mật",
        Cookies: "Cookie",
      },
      roadmap: {
        heading: "Lộ trình",
        heading1: "Mọi thứ bắt đầu từ đâu",
        phase: "Giai đoạn",
        phase1: {
          line1: "Tạo đội",
          line2: "Phát hành thử nghiệm beta Game QuBy Ai",
          line3: "Thiết kế và Phát hành website: Token QuBy Ai",
          line4: "Khởi chạy Mạng xã hội trên Twitter và Telegram",
          line5: "Bắt đầu cuộc thi Meme hàng tháng",
        },
        phase2: {
          line1: "Mở rộng đội ngũ",
          line2: "Phát hành WhitePaper V.1",
          line3: "Khởi chạy Game QuBy Ai miễn phí để chơi: Cấp độ Qubaria",
          line4: "Đạt 10.000 người theo dõi trên Twitter và Telegram",
          line5: "Bắt đầu Đăng ký mua trước",
          line6: "Khởi đầu Khuyến mãi Airdrop",
          line7: "Thiết kế và Phát hành website: Game QuBy Ai",
          line8: "Thiết kế và Phát hành website: QuByChain Blockchain",
        },
        phase3: {
          line1: "Kiểm định Hợp đồng thông minh $QYAI",
          line2: "Bắt đầu mua trước trên Website QuBy Ai và Nền tảng Khởi chạy",
          line3: "Triển khai Hợp đồng với Cơ chế Đốt cháy",
          line4: "Niêm yết trên Sàn giao dịch Trung tâm (DEX)",
          line5: "Niêm yết trên CoinGecko và CoinMarketCap",
          line6: "Bắt đầu Staking Token $QUBAI",
          line7: "Khởi chạy Cấp độ Game mới",
          line8: "Giới thiệu Phần thưởng Người chơi Xếp hạng Thế giới",
        },
        phase4: {
          line1: "Mở rộng đội ngũ tiếp",
          line2: "Triển khai NFT và Giới thiệu Nhân vật Game mới",
          line3: "Mỗi tháng phát hành Cấp độ Game mới",
          line4: "Khai mạc Cúp Quốc tế Game QuBy",
          line5: "Niêm yết trên Sàn giao dịch Trung tâm lớn (DEX)",
          line6: "Hợp tác và Tiếp thị Quốc tế cho Game",
          line7: "Khởi chạy phiên bản Web 3.0 chơi để kiếm sử dụng Token $QYAI",
          line8: "Bắt đầu Phát triển Blockchain QuByChain",
          line9: "Khởi chạy Website QuBySwap",
          line10: "Thông tin bổ sung sẽ được tiết lộ...",
        },
      },
    },
  },
};

export default translations;
